import {Outlet, useLocation} from 'react-router';
import {Box, Container, Link, Skeleton, Stack, Typography} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {useGetCompanySettingsQuery} from '../../app/slices/public';
import PhoneNumber from './modules/PhoneNumber';

import './styles.scss';
import {useEffect} from 'react';

const Root = () => {
  const {data: companyInfo = {}, isLoading} = useGetCompanySettingsQuery();
  const location = useLocation();

  document.getElementsByTagName('title')[0].innerText = companyInfo?.title;

  if (companyInfo?.htmlChat && companyInfo?.htmlCounterYaMetrika) {
    const chat = new DOMParser().parseFromString(companyInfo.htmlChat, 'application/xml');
    const chatScript = document.createElement('script');

    chatScript.type = 'text/javascript';
    chatScript.innerText = chat.documentElement.innerHTML;
    document.body.appendChild(chatScript);
  }

  useEffect(() => {
    if (!companyInfo) return;
    window.ym(companyInfo?.htmlCounterYaMetrika, 'hit', window.location.href);
  }, [location, companyInfo]);

  return isLoading ? (
    <>
      <div className="header">
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between">
            <Skeleton sx={{height: {md: 80, sm: 60, s: 40}, width: {md: 295, sm: 215, s: 150}}} />
            <Stack direction="row" alignItems="center" spacing={{xl: 7, lg: 5, md: 4, xs: 2}}>
              {location.pathname !== '/' && <Skeleton width={85} height={40} />}
              <Skeleton sx={{height: {sm: 60, s: 40}, width: {sm: 175, s: 30}}} />
            </Stack>
          </Stack>
        </Container>
      </div>
      <div id="detail">
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </div>
    </>
  ) : (
    <>
      <div className="header">
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between">
            <Box className="header__title">
              {!companyInfo?.companyLogo ? (
                <Link
                  href={companyInfo?.site}
                  className="header__title-link"
                  variant="h6"
                  target="_blank"
                  rel="noreferrer">
                  {companyInfo?.title}
                </Link>
              ) : (
                <a href={companyInfo?.site} target="_blank" rel="noreferrer">
                  <img src={companyInfo?.companyLogo} alt={companyInfo?.title}></img>
                </a>
              )}
            </Box>
            <Stack direction="row" alignItems="center" spacing={{xl: 7, lg: 5, md: 4, xs: 2}}>
              {location.pathname !== '/' && (
                <Link className="header__back-button" component={RouterLink} to={'/'}>
                  Войти
                </Link>
              )}
              <PhoneNumber phoneNumber={companyInfo?.phone} />
            </Stack>
          </Stack>
        </Container>
      </div>
      <div id="detail">
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </div>
    </>
  );
};

export default Root;

import {Link, useParams} from 'react-router-dom';
import {Button, Container, Link as TypographyLink, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Loader from '../../components/Loader';

import {useUpdateDeadSoulQuery} from '../../app/slices/auth';
import {DEAD_SOUL_STATUS} from '../../constants';

const DeadSouls = () => {
  const {deadSoulHash, deadSoulStatusId} = useParams();

  const {isSuccess, isError} = useUpdateDeadSoulQuery({deadSoulHash, deadSoulStatusId});

  if (isError) {
    return (
      <Container maxWidth="sm">
        <Stack spacing={3} mt={15} alignItems="center">
          <ErrorIcon sx={{fontSize: 80}} color="error" />
          <Typography variant="h4" textAlign="center" gutterBottom>
            Приносим извинения,произошла непредвиденная ошибка!
          </Typography>
          <TypographyLink component={Link} to="/" color="#fff" align="center">
            <Button variant="contained" size="large">
              Вернуться на главную
            </Button>
          </TypographyLink>
        </Stack>
      </Container>
    );
  }

  if (!isSuccess) {
    return <Loader />;
  }

  return (
    <Container maxWidth="sm">
      <Stack spacing={3} mt={15} alignItems="center">
        <DoneAllIcon sx={{fontSize: 80}} color="success" />
        <Typography variant="h4" textAlign="center" gutterBottom>
          {deadSoulStatusId === DEAD_SOUL_STATUS.notNeed ? 'Ваш договор будет удален в ближащее время!' : null}
          {deadSoulStatusId === DEAD_SOUL_STATUS.addTime ? 'Запрос на продление договора успешно принят!' : null}
        </Typography>
        <Button variant="contained" size="large">
          <TypographyLink component={Link} to="/" color="#fff" align="center">
            Вернуться на главную
          </TypographyLink>
        </Button>
      </Stack>
    </Container>
  );
};

export default DeadSouls;

import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {Button, Stack, Typography, Box, useMediaQuery, InputAdornment} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import {defaultStudent} from '../../constants';
import NestedTextField from '../../components/InputsRegister/ControlFields/TextField';
import NestedAutocomplete from '../../components/InputsRegister/ControlFields/Autocomplete';

import RegistrationStep from './modules/RegistrationStep';
import ListenerCardInfoTitle from './modules/ListenerCardInfoTitle';
import EducationPrograms from './modules/educationPrograms';
import HelpIcon from '@mui/icons-material/Help';

const CreateStudent = ({getContractCost}) => {
  const {fields, append, remove} = useFieldArray({name: 'students'});
  const {control} = useFormContext();
  const students = useWatch({control, name: 'students'});

  const isDesktop = useMediaQuery('(min-width:768px)');

  const removeStudent = (index) => () => {
    remove(index);
    getContractCost();
  };

  return (
    <RegistrationStep
      step="Шаг 2 из 4"
      title="Заполнение информации о слушателях и выбор программы"
      subtitle="Введите данные о слушателях и программы обучения для каждого из них"
      warning={
        <Stack direction="row" mt={{md: 1, s: 0.5}}>
          <PriorityHighIcon color="warning" sx={{fontSize: {lg: '24px', s: '16px'}}} />
          <Typography variant="subtitle2">
            Будьте внимательны! Указанные данные используются для подготовки оригиналов документов!
          </Typography>
        </Stack>
      }>
      {fields.map((field, index) => (
        <Box key={field.id} className="student__card" mt={{lg: 4, sm: 2.5, s: 2}} mb={{lg: 4, sm: 2.5, s: 2}}>
          <Stack direction={{sm: 'row'}} spacing={2}>
            <Box width={{sm: '45%'}}>
              <ListenerCardInfoTitle
                studentNumber={index + 1}
                studentQuantity={fields.length}
                removeStudent={removeStudent(index)}
                addStudent={() => append(defaultStudent)}
              />
              <Stack spacing={1}>
                <NestedAutocomplete
                  name={`students[${index}].surname`}
                  label="Фамилия*"
                  type="fio"
                  pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
                />
                <NestedAutocomplete
                  name={`students[${index}].firstname`}
                  label="Имя*"
                  type="fio"
                  pattern={/^(^[-а-яА-ЯёЁ  \s]+$[^0-9]*)$/}
                />
                <NestedAutocomplete
                  name={`students[${index}].secondname`}
                  label="Отчество"
                  type="fio"
                  pattern={/^(^[-а-яА-ЯёЁ  \s]+$[^0-9]*)$/}
                />
                <NestedTextField name={`students[${index}].jobPlace`} label="Место работы*" multiline />
                <NestedTextField name={`students[${index}].profession`} label="Должность*" multiline />
              </Stack>
              {isDesktop ? (
                <Typography variant="subtitle2" mt={{lg: 3.5, xs: 2.5, s: 2}}>
                  * — поля, обязательные для заполнения
                </Typography>
              ) : null}
            </Box>
            <EducationPrograms
              studentIndex={index}
              getContractCost={getContractCost}
              studentQuantity={fields.length}
              removeStudent={removeStudent(index)}
              previousStudentPrograms={students[index - 1]?.programs}
            />
          </Stack>
        </Box>
      ))}
      <Button variant="contained" size="large" fullWidth onClick={() => append(defaultStudent)}>
        Добавить слушателя
      </Button>
    </RegistrationStep>
  );
};
export default CreateStudent;

export const BasisType = [
  {
    id: '00395456-ae3f-4c65-9cf7-d615d480bdd2',
    label: 'Устава',
  },
  {
    id: '1ba9efc0-436a-4de6-89da-76184b10b4d3',
    label: 'Приказа',
  },
  {
    id: '26fe13e7-80be-4776-b1a9-f95e2443fc43',
    label: 'Доверенности',
  },
  {
    id: '58b2326f-77d5-47a4-981f-401f35f85ea6',
    label: 'Распоряжения',
  },
  {
    id: 'c55149ab-38f0-4d0e-95ef-4ae79e161e13',
    label: 'Положения',
  },
];

import Select from 'react-select';
import {Button, Stack, Typography, useMediaQuery} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {CSSTransition} from 'react-transition-group';
import {Controller, get, useFormContext, useWatch} from 'react-hook-form';
import {skipToken} from '@reduxjs/toolkit/query';
import {getCompanySettings, useGetProgramsByTypeQuery, useGetProgramTypesQuery} from '../../../app/slices/public';
import useToggle from '../../../hooks/useToggle';

const ProgramsController = ({studentIndex, programIndex, getContractCost}) => {
  const {data: programTypes} = useGetProgramTypesQuery();
  const {data: company} = getCompanySettings.useQueryState();
  const [isOpen, toggle] = useToggle();
  const [isEisotOpen, toggleEisot] = useToggle();

  const isMobile = useMediaQuery('(min-width:480px)');

  const trainingProgramTypeName = `students[${studentIndex}].programs[${programIndex}].trainingProgramTypeId`;
  const trainingProgramName = `students[${studentIndex}].programs[${programIndex}].trainingProgramId`;

  const {
    control,
    resetField,
    formState: {errors},
  } = useFormContext();

  const selectedTypeProgram = useWatch({name: trainingProgramTypeName});
  const programsPerStudent = useWatch({name: `students[${studentIndex}].programs`});

  const programTypesPerStudentList = programsPerStudent.map((elem) => elem.trainingProgramTypeId.id);
  const programsPerStudentList = programsPerStudent.map((elem) => elem?.trainingProgramId?.id);
  const programType = programTypes?.data.find((program) => program.id === selectedTypeProgram?.id);

  const {data: programs, isLoading} = useGetProgramsByTypeQuery(
    selectedTypeProgram?.id ? selectedTypeProgram.id : skipToken
  );

  const trainingProgramTypeError = get(errors, trainingProgramTypeName);
  const trainingProgramError = get(errors, trainingProgramName);

  return (
    <Stack spacing={0.5} mb={2}>
      <Controller
        control={control}
        name={trainingProgramTypeName}
        render={({field: {value, name, onChange, onBlur, ref}}) => (
          <>
            <Select
              name={name}
              value={value}
              placeholder="Выберите тип программы обучения"
              options={programTypes?.data}
              getOptionLabel={(option) => option.fullName}
              getOptionValue={(option) => option.id}
              isOptionDisabled={(option) => !option.canMultiSelect && programTypesPerStudentList.includes(option.id)}
              onChange={(props) => {
                onChange(props);
                resetField(trainingProgramName, {defaultValue: ''});
              }}
              onBlur={onBlur}
              components={{IndicatorSeparator: () => null}}
              classNamePrefix="select"
              ref={ref}
            />
            <Typography color="error" align="center" gutterBottom>
              {trainingProgramTypeError?.message}
            </Typography>
          </>
        )}
      />
      <Controller
        control={control}
        name={trainingProgramName}
        render={({field: {name, value, onChange, onBlur, ref}}) => (
          <>
            <Select
              name={name}
              value={value}
              placeholder="Выберите программу обучения"
              options={programs?.data}
              onChange={onChange}
              onBlur={(e) => {
                onBlur(e);
                getContractCost(e);
              }}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => `${option.name} ${option.text} (${option.hoursCount} ч)`}
              isOptionDisabled={(option) => programsPerStudentList.includes(option.id)}
              isLoading={isLoading}
              noOptionsMessage={() => 'нет доступных программ'}
              loadingMessage={() => 'Загрузка...'}
              components={{IndicatorSeparator: () => null}}
              blurInputOnSelect
              classNamePrefix="select"
              ref={ref}
            />
            <Typography color="error" align="center" gutterBottom>
              {trainingProgramError?.message}
            </Typography>
          </>
        )}
      />
      {programType?.needDiplom && (
        <Stack mt={{lg: 3.5, md: 2.5, s: 2}} mb={{lg: 3.5, md: 2.5, s: 2}} gap={1} alignItems="start">
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={toggle}
            fullWidth={!isMobile}
            style={{backgroundColor: '#fff'}}
            startIcon={<PriorityHighIcon />}>
            <Typography variant="caption" color="#1a1a1a">
              Посмотреть предупреждение
            </Typography>
          </Button>
          <CSSTransition in={isOpen} timeout={300} classNames="alert" unmountOnExit>
            <Typography variant="body1">
              ВНИМАНИЕ! В соответствии с п. 3 ст. 76 Федерального закона от 29.12.2012 N 273-ФЗ "Об образовании в
              Российской Федерации" требуется загрузить в личном кабинете слушателя (после подачи данной заявки)
              <b> скан-копию документа,</b> подтверждающего наличие у слушателя высшего или среднего профессионального
              образования (диплом).
            </Typography>
          </CSSTransition>
        </Stack>
      )}
      {programType?.isEisot && (
        <Stack mt={{lg: 3.5, md: 2.5, s: 2}} mb={{lg: 3.5, md: 2.5, s: 2}} alignItems="start" gap={1}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={toggleEisot}
            fullWidth={!isMobile}
            style={{backgroundColor: '#fff'}}
            startIcon={<PriorityHighIcon />}>
            <Typography variant="caption" color="#1a1a1a">
              Посмотреть предупреждение ЕИСОТ
            </Typography>
          </Button>
          <CSSTransition in={isEisotOpen} timeout={300} classNames="alert" unmountOnExit>
            <Typography variant="body1">
              ВНИМАНИЕ! Обучение по данным программам предназначено только для категорий лиц, указанных в п. 78 Правил
              обучения по охране труда и проверки знания требований охраны труда, утвержденных постановлением
              Правительства РФ от 24.12.2021 № 2464. Обучение включает тренажёр тестов по вопросам ЕИСОТ, размещенным на
              сайте Минтруда. В процессе обучения сдается внутренний экзамен, что позволяет слушателям ознакомиться с
              вопросами и подготовиться к дальнейшему обязательному тестированию в ЕИСОТ на сайте Минтруда
            </Typography>
          </CSSTransition>
        </Stack>
      )}
    </Stack>
  );
};

export default ProgramsController;

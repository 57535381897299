import {Box, Link, Stack, Typography} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {useSendFormMutation} from '../../app/slices/auth';
import {useWatch} from 'react-hook-form';
import {toast} from 'react-toastify';

const HelpCreatContract = ({id, phone}) => {
  const [sendForm] = useSendFormMutation();

  const email = useWatch({name: 'user.login'});

  const sendFormOnEmail = () => {
    email
      ? sendForm({id, email})
          .unwrap()
          .then((res) => toast.success('форма успешно отправлена на ваш email'))
          .catch((err) => toast.error(err.data.message))
      : toast.error('Пожалуйста, укажите email на первом этапе заполнения формы');
  };

  return (
    <Box sx={{p: 2, borderRadius: 2, boxShadow: '0 0 10px rgba(0, 0, 0, 0.3);'}}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        onClick={sendFormOnEmail}
        spacing={1}
        mb={2}
        sx={{cursor: 'pointer'}}>
        <EmailIcon />
        <Typography variant="caption">Отправьте мне на почту — заполню позже</Typography>
      </Stack>
      <Typography variant="h5" textAlign="center">
        В случае трудностей с оформлением заявки, свяжитесь с нами
      </Typography>
      <Stack direction={{sm: 'row'}} justifyContent="space-around" alignItems="center" mt={2} mb={2}>
        <Stack spacing={1} alignItems="center" mb={{sm: 0, s: 2}}>
          <PhoneIcon color="primary" sx={{fontSize: 40}} />
          <Link href={`tel:${phone}`} textAlign="center">
            {phone}
          </Link>
          <Typography fontWeight={600} textAlign="center">
            Заказать обратный звонок
          </Typography>
          <Typography variant="caption">Звонок бесплатный</Typography>
        </Stack>
        <Stack spacing={1} alignItems="center" mb={{sm: 0, s: 2}}>
          <ChatBubbleOutlineIcon color="primary" sx={{fontSize: 40}} />
          <Typography fontWeight={600} textAlign="center">
            С помощью чата
          </Typography>
          <Typography textAlign="center"> (находится справа)</Typography>
        </Stack>
        <Stack spacing={1} alignItems="center" maxWidth={200}>
          <EmailIcon color="primary" sx={{fontSize: 40}} />
          <Typography textAlign="center">
            Либо{' '}
            <b style={{cursor: 'pointer'}} onClick={sendFormOnEmail}>
              отправьте форму заявки на свой e-mail
            </b>{' '}
            и вернитесь к ней позже
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default HelpCreatContract;

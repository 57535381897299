import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Backdrop, Button, Fade, Link as TypographyLink, Modal, Stack, Typography} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box} from '@mui/material';

import {schema} from './schema';

import AuthForm from './modules/AuthForm';

const Login = () => {
  const [authGoal, setAuthGoal] = useState('education');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const methods = useForm({
    defaultValues: schema[authGoal].cast(),
    resolver: yupResolver(schema[authGoal]),
  });

  const switchAuthGoal = (userGoal) => {
    setAuthGoal(userGoal);
  };

  const navigateToAb = () => {
    window.open('https://sdo-auth.ab-dpo.ru/create-contract');
  };

  return (
    <Box
      maxWidth={{lg: 810, md: 780, sm: 700}}
      m="auto"
      mt={{xl: 12, lg: 7.5, md: 6, sm: 4, xs: 3, s: 2.5}}
      className="auth">
      <Typography textAlign="center" variant="h1" mb={{md: 8.5, sm: 7.5, xs: 6, s: 4}}>
        Выберите цель входа в личный кабинет
      </Typography>
      <Stack spacing={{lg: 3, sm: 2.5, xs: 1, s: 0}} useFlexGap={true}>
        <FormProvider {...methods}>
          <AuthForm
            isFormActive={authGoal === 'education'}
            setAuthGoal={() => switchAuthGoal('education')}
            goal="Обучение"
            description="Изучение учебного материала, тестирование, экзамен"
            loginLabel="Фамилия"
            passwordLabel="Номер курса"
            loginPlaceholder="Алексеев">
            <Typography
              variant="caption"
              textAlign="center"
              display="block"
              onClick={handleOpen}
              sx={{cursor: 'pointer'}}>
              Где взять номер курса?
            </Typography>
            <Modal
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{backdrop: Backdrop}}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}>
              <Fade in={open}>
                <Box className="modal">
                  <Typography variant="h6">Логин — фамилия слушателя (без имени и отчества)</Typography>
                  <Typography variant="h6">Пароль — номер курса (цифры)</Typography>
                  <Typography variant="h6">Эти данные указаны:</Typography>
                  <Typography sx={{mt: 1}} variant="body1">
                    1. в приложении №1 к Договору
                  </Typography>
                  <Typography variant="body1">
                    2. в электронном письме «Информация об обучаемых в группе», отправленном автоматически после
                    оформления заявки
                  </Typography>
                  <Typography variant="body1">3. в разделе «Мой кабинет» (у администратора учебной группы)</Typography>

                  <Button onClick={handleClose} variant="contained" fullWidth sx={{mt: 2}}>
                    Понятно
                  </Button>
                </Box>
              </Fade>
            </Modal>
          </AuthForm>
          <AuthForm
            isFormActive={authGoal === 'moderate'}
            setAuthGoal={() => switchAuthGoal('moderate')}
            goal="Мой кабинет"
            description="Управление данными слушателей, редактирование программ обучения, оплата"
            loginLabel="Email"
            passwordLabel="Пароль"
            loginPlaceholder="aleksey@mail.ru">
            <TypographyLink component={Link} to="forgot-password" variant="caption" color="#999999" align="center">
              Я забыл пароль
            </TypographyLink>
            <TypographyLink component={Link} onClick={navigateToAb} color="primary" align="center">
              Регистрация (создание заявки)
            </TypographyLink>
          </AuthForm>
        </FormProvider>
      </Stack>
    </Box>
  );
};

export default Login;

import {useFormContext, useWatch} from 'react-hook-form';
import {CSSTransition} from 'react-transition-group';
import dayjs from 'dayjs';
import {Box, Typography} from '@mui/material';

import {CustomerType} from '../../constants';

import RegistrationStep from './modules/RegistrationStep';
import IndividualForm from './modules/contractInfo/individualForm';
import RadioButtonsController from './modules/contractInfo/radioButtonsController';
import EntityForm from './modules/contractInfo/entityForm';
import IndividualEntrepreneurForm from './modules/contractInfo/individualEntrepreneurForm';
import InformationBlock from './modules/contractInfo/informationBlock';
import {useGetCompanySettingsQuery} from '../../app/slices/public';

const ContractInfo = ({customerType, setCustomerType}) => {
  const {
    unregister,
    setValue,
    getValues,
    control,
    formState: {errors},
  } = useFormContext();

  const isBudget = useWatch({name: 'customer.isBudget'});
  const basisType = useWatch({name: 'customer.basisTypeId'});
  const isSameAddress = useWatch({name: 'isSameAddress'});
  const isForeignCitizenship = useWatch({name: 'customer.isForeignCitizenship'});
  const {data: companyInfo} = useGetCompanySettingsQuery();

  const onCustomerTypeChange = (e) => {
    setCustomerType(e.target.value);
    unregister('customer');
    setValue('customer.customerTypeId', e.target.value);
    if (isSameAddress) {
      setValue('isSameAddress', false);
    }
  };

  const autoFill = (e) => {
    const values = getValues(['user.surname', 'user.firstname', 'user.secondname', 'user.login', 'user.phone']);

    if (e.target.value !== '' && e.target.value === values[0]) {
      setValue('customer.name', values[1], {shouldValidate: true});
      setValue('customer.secondName', values[2], {shouldValidate: true});
      setValue('customer.email', values[3], {shouldValidate: true});
      setValue('customer.phone', values[4], {shouldValidate: true});
    }
  };

  const autoFillCompanyFields = (e, data) => {
    const fullName = data?.data.management ? data?.data.management.name.split(' ') : '';
    const profession = data?.data.management ? data?.data.management.post : '';
    const date = dayjs(data?.data.ogrn_date).format('YYYY-MM-DD');

    setValue('customer.inn', data?.data.inn, {shouldValidate: true});
    switch (data?.data.type) {
      case 'LEGAL':
        setValue('customer.kpp', data?.data.kpp, {shouldValidate: true});
        setValue('customer.directorSurname', fullName[0], {shouldValidate: true});
        setValue('customer.directorName', fullName[1], {shouldValidate: true});
        setValue('customer.directorSecondName', fullName[2], {shouldValidate: true});
        setValue('customer.directorProfession', profession, {shouldValidate: true});
        setValue('customer.basisDate', date, {shouldValidate: true});
        setValue('customer.legalAddress', data?.data.address.unrestricted_value, {shouldValidate: true});
        break;
      case 'INDIVIDUAL':
        setValue('customer.ogrn', data?.data.ogrn, {shouldValidate: true});
        setValue('customer.directorSurname', data?.data.fio.surname, {shouldValidate: true});
        setValue('customer.directorName', data?.data.fio.name, {shouldValidate: true});
        setValue('customer.directorSecondName', data?.data.fio?.patronymic, {shouldValidate: true});
        break;
      default:
        return null;
    }
  };

  const autoFillBankFields = (e, data) => {
    setValue('customer.bik', data.data.bic, {shouldValidate: true});
    setValue('customer.kAccount', data.data.correspondent_account, {shouldValidate: true});
  };
  const autoFillBikFields = (e, data) => {
    if (!data) {
      setValue('customer.bik', '', {shouldValidate: true});
    } else {
      setValue('customer.bik', data.data.bic, {shouldValidate: true});
      setValue('customer.bank', data.value, {shouldValidate: true});
      setValue('customer.kAccount', data.data.correspondent_account, {shouldValidate: true});
    }
  };

  const setDeliveryAddress = (e, value) => {
    setValue('customer.postAddress', value, {shouldValidate: true});
  };

  return (
    <RegistrationStep
      step="Шаг 3 из 4"
      title="Заполнение информации для подготовки договора"
      subtitle="Ваши персональные данные требуются для подготовки Договора на оказание образовательных услуг в целях реализации ст.54 Федерального закона от 29.12.2012 N273-ФЗ «Обобразовании в Российской Федерации»"
      warning={
        <Typography variant="subtitle2">
          {companyInfo?.fullTitle} является официально зарегистрированным Роскомнадзором оператором персональных данных
          {companyInfo?.rknNumber && `номер в реестре операторов - ${companyInfo?.rknNumber}`}
        </Typography>
      }
      notification="*— поля, обязательные для заполнения">
      <InformationBlock title="Выберите тип заказчика">
        <RadioButtonsController control={control} onCustomerTypeChange={onCustomerTypeChange} />
      </InformationBlock>
      <Box mb={{lg: 3.5, sm: 2.5, s: 2}}>
        <CSSTransition in={customerType === CustomerType.individual} timeout={300} classNames="customer" unmountOnExit>
          <IndividualForm
            isForeignCitizenship={isForeignCitizenship}
            autoFill={autoFill}
            setDeliveryAddress={setDeliveryAddress}
          />
        </CSSTransition>
        <CSSTransition in={customerType === CustomerType.entity} timeout={300} classNames="customer" unmountOnExit>
          <EntityForm
            control={control}
            isBudget={isBudget}
            basisType={basisType}
            errors={errors}
            autoFillBankFields={autoFillBankFields}
            autoFillBikFields={autoFillBikFields}
            autoFillCompanyFields={autoFillCompanyFields}
            setDeliveryAddress={setDeliveryAddress}
          />
        </CSSTransition>
        <CSSTransition
          in={customerType === CustomerType.individualEntrepreneur}
          timeout={300}
          classNames="customer"
          unmountOnExit>
          <IndividualEntrepreneurForm
            autoFillCompanyFields={autoFillCompanyFields}
            autoFillBankFields={autoFillBankFields}
            autoFillBikFields={autoFillBikFields}
            setDeliveryAddress={setDeliveryAddress}
          />
        </CSSTransition>
      </Box>
    </RegistrationStep>
  );
};

export default ContractInfo;

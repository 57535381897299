// import {Link} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {Controller, FormProvider, get, useForm, useWatch} from 'react-hook-form';
import {Box, Button, Container, Stack, Typography, Checkbox, FormControlLabel, Skeleton, Link} from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import NestedTextField from '../../components/InputsRegister/ControlFields/TextField';
import {
  useGetCommercialProgramsQuery,
  useGetCompanySettingsQuery,
  useSendCommercialProposalMutation,
} from '../../app/slices/public';
import {schema} from './schema';
import {useEffect, useState} from 'react';
import {SmartCaptcha} from '@yandex/smart-captcha';

const CommercialProposal = () => {
  const {data: programs, isLoading: isProgramsLoading} = useGetCommercialProgramsQuery();
  const [sendCommercialProposal, {data: offerInfo, isSuccess: isOfferSent, isLoading: isOfferSending}] =
    useSendCommercialProposalMutation();
  const {data: companySettings} = useGetCompanySettingsQuery();
  const [captchaToken, setCaptchaTokenToken] = useState(null);
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const methods = useForm({resolver: yupResolver(schema), defaultValues: schema.cast()});

  const programsError = get(methods.formState.errors, 'trainingProgramTypes');
  const selectedPrograms = useWatch({name: 'trainingProgramTypes', control: methods?.control});

  const selectedProgramsList = programs?.data.map((program) => program.id);
  const emptyProgramsList = programs?.data.map(() => '');

  const isAllProgramsSelected = selectedPrograms.filter((program) => program).length === selectedProgramsList?.length;

  const handleReset = () => setResetCaptcha((prev) => prev + 1);
  const onSubmit = (data) => {
    if (companySettings?.isCaptchaEnabled === true) {
      data['captchaSmartToken'] = captchaToken;
    }

    sendCommercialProposal(data)
      .then((res) => methods.reset())
      .catch((e) => {
        handleReset();
        setCaptchaTokenToken(null);
      });
  };
  const toggleCheckboxes = (e) => {
    const {checked} = e.target;
    checked
      ? methods.setValue('trainingProgramTypes', selectedProgramsList, {shouldValidate: true})
      : methods.setValue('trainingProgramTypes', emptyProgramsList, {shouldValidate: true});
  };

  const isCaptchaEnabled = companySettings?.isCaptchaEnabled === true;

  useEffect(() => {
    window.open('https://sdo-auth.ab-dpo.ru/create-contract', '_self');
  }, [window]);

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition key={isOfferSent ? 'success' : 'form'} timeout={300} classNames="customer">
          {isOfferSent ? (
            <Stack justifyContent="center" alignItems="center" spacing={2} mt={{xs: 5, sm: 10}}>
              <DoneAllIcon color="success" sx={{fontSize: {xs: 60, sm: 90, md: 120}}} />
              <Typography variant="h1" fontSize={{xs: 18, sm: 24}} textAlign="center" mt={4}>
                Коммерческое предложение успешно отправлено на Вашу электронную почту: {offerInfo.email}!
              </Typography>
              <Button component={Link} href={companySettings?.site || '/'} variant="contained">
                Вернуться на главную
              </Button>
            </Stack>
          ) : (
            <Box>
              <Typography variant="h1" fontSize={{xs: 24, sm: 36, md: 50}} textAlign="center" mt={4}>
                Запрос коммерческого предложения
              </Typography>
              <Container maxWidth="md">
                <FormProvider {...methods}>
                  <Box component="form" mt={6} onSubmit={methods.handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                      <Stack>
                        <Typography variant="body1" gutterBottom>
                          Укажите название Вашей организации:
                        </Typography>
                        <NestedTextField name="companyName" placeholder="ПАО «Северсталь»" />
                      </Stack>
                      <Stack>
                        <Typography variant="body1" gutterBottom>
                          Укажите E-mail для получения коммерческого предложения:
                        </Typography>
                        <NestedTextField name="email" placeholder="temporibus@rambler.ru" />
                      </Stack>
                      <Stack>
                        <Typography variant="body1" gutterBottom>
                          Выберите типы обучения:
                        </Typography>
                        <FormControlLabel
                          label="Выбрать все"
                          control={<Checkbox checked={isAllProgramsSelected} onChange={toggleCheckboxes} />}
                        />
                      </Stack>
                      {isProgramsLoading && (
                        <Stack direction="row" justifyContent="space-between" alignItems="top" flexWrap="wrap" mb={2}>
                          <Skeleton height={50} sx={{flex: {xs: '0 1 100%', md: '0 1 48%'}}} />
                          <Skeleton height={50} sx={{flex: {xs: '0 1 100%', md: '0 1 48%'}}} />
                          <Skeleton height={50} sx={{flex: {xs: '0 1 100%', md: '0 1 48%'}}} />
                          <Skeleton height={50} sx={{flex: {xs: '0 1 100%', md: '0 1 48%'}}} />
                          <Skeleton height={50} sx={{flex: {xs: '0 1 100%', md: '0 1 48%'}}} />
                          <Skeleton height={50} sx={{flex: {xs: '0 1 100%', md: '0 1 48%'}}} />
                        </Stack>
                      )}
                      <Stack direction="row" alignItems="top" flexWrap="wrap" mb={2}>
                        {programs?.data.map((program, index) => (
                          <Box key={program.id} sx={{flex: {xs: '0 1 100%', md: '0 1 50%'}}} mb={1}>
                            <Controller
                              control={methods.control}
                              name={`trainingProgramTypes[${index}]`}
                              defaultValue=""
                              render={({field: {onChange, onBlur, name, value, ref}}) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedPrograms.includes(program.id)}
                                      value={value}
                                      onChange={(e) => onChange(e.target.checked ? program.id : '')}
                                      onBlur={onBlur}
                                      name={name}
                                      ref={ref}
                                    />
                                  }
                                  label={program.fullName}
                                />
                              )}
                            />
                          </Box>
                        ))}
                      </Stack>
                      {programsError && (
                        <Typography color="error" variant="body2" textAlign="center">
                          {programsError.message}
                        </Typography>
                      )}
                      <Stack my={2}>
                        <Box sx={{alignSelf: 'center'}}>
                          {isCaptchaEnabled && (
                            <SmartCaptcha
                              key={resetCaptcha}
                              sitekey={companySettings?.captchaClientKey}
                              onSuccess={setCaptchaTokenToken}
                            />
                          )}
                        </Box>
                      </Stack>
                      <Button
                        disabled={(isCaptchaEnabled && captchaToken === null) || isOfferSending}
                        type="submit"
                        variant="contained"
                        size="large">
                        Отправить
                      </Button>
                    </Stack>
                  </Box>
                </FormProvider>
              </Container>
            </Box>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export default CommercialProposal;

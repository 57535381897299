import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {Box, Link, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';
import {
  useCreateContractMutation,
  useGetFormQuery,
  useSendFirstFormMutation,
  useUpdateFormMutation,
} from '../../app/slices/auth';
import {schema} from './schema';
import {CustomerType, DeliveryType, defaultStudent, BasisType} from '../../constants';
import {useCalcContractCostMutation, useGetCompanySettingsQuery} from '../../app/slices/public';

import SignUpPersonalArea from './signUpPersonalArea';
import CreateStudent from './createStudent';
import ContractInfo from './contractInfo';
import DeliveryInfo from './deliveryInfo';

import HelpCreatContract from './helpCreateContract';

import removeEmptyFields, {trimFields} from '../../utils/removeEmptyFields';
import {getErrorsList} from '../../utils/errosList';
import PageTitle from '../../components/PageTitle';
import {SmartCaptcha} from '@yandex/smart-captcha';

const customer = {
  [CustomerType.individual]: 'individual',
  [CustomerType.entity]: 'entity',
  [CustomerType.individualEntrepreneur]: 'entrepreneur',
};

const CreateContract = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [customerType, setCustomerType] = useState(CustomerType.individual);
  const [formFields, setFormFields] = useState();
  const {data: companyInfo = {}} = useGetCompanySettingsQuery();
  const [createContract, {isError, isLoading}] = useCreateContractMutation();
  const [captchaToken, setCaptchaTokenToken] = useState(null);
  const [resetCaptcha, setResetCaptcha] = useState(0);
  const [calcContractCost, {data: cost}] = useCalcContractCostMutation({
    fixedCacheKey: 'contract-price',
  });

  const regFormId = searchParams.get('regFormId');
  const formId = localStorage.getItem('regForm');

  const [updateForm] = useUpdateFormMutation();
  const [sendFirstForm, {isSuccess: isSuccessFirstForm}] = useSendFirstFormMutation();
  const {data: formData, isSuccess, error, isError: isRegFormError} = useGetFormQuery(regFormId ? regFormId : formId);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema[customerType]),
    defaultValues: {
      ...schema[customerType].cast(),
      deliveryType: 'customerAddress',
      deliveryTypeId: DeliveryType.postOfRussia,
    },
  });

  const {touchedFields} = methods.formState;

  /* Update the state */
  const handleReset = () => setResetCaptcha((prev) => prev + 1);

  useEffect(() => {
    const formFields = JSON.parse(formData ? formData?.data : null);
    if (formFields) {
      setCustomerType(formFields.customer.customerTypeId);
      methods.reset(formFields);
      if (formFields?.deliveryType === '' || formFields?.deliveryType === null) {
        methods.setValue('deliveryType', 'customerAddress');
      }
      getContractCost();
    }
    if (!formId) {
      const defaultForm = JSON.stringify(schema[customerType].cast());
      sendFirstForm(defaultForm)
        .unwrap()
        .then(({id}) => localStorage.setItem('regForm', id));
    }
    if (regFormId) {
      localStorage.setItem('regForm', regFormId);
    }
  }, [formData]);

  useEffect(() => {
    if (touchedFields) {
      const data = JSON.stringify(methods.getValues());
      setFormFields(data);
    }
  }, [methods.formState]);

  useEffect(() => {
    if (error?.data?.message === 'The given data was invalid.') {
      const defaultForm = JSON.stringify(schema[customerType].cast());
      sendFirstForm(defaultForm)
        .unwrap()
        .then(({id}) => localStorage.setItem('regForm', id));
    }
  }, [isRegFormError, error]);

  useEffect(() => {
    const regFormId = localStorage.getItem('regForm');

    if (regFormId && (isSuccess || isSuccessFirstForm)) {
      console.log('here');
      updateForm({id: regFormId, data: formFields})
        .unwrap()
        .then(({id}) => localStorage.setItem('regForm', id));
    }
  }, [formFields]);

  // useEffect(()=>{
  //   if(isError === true)
  // },[isError])

  const onSubmit = (formData) => {
    formData.customer.passportIssuedDate = formData?.customer?.passportIssuedDate
      ? dayjs(formData.customer.passportIssuedDate).format('DD.MM.YYYY')
      : null;

    formData.customer.basisDate =
      formData?.customer?.basisDate && formData?.customer?.basisTypeId !== BasisType[0].id
        ? dayjs(formData.customer.basisDate).format('DD.MM.YYYY')
        : null;

    if (formData?.deliveryType === 'anotherAddress') formData.customer.postAddress = formData?.postAddress;
    else if (formData?.deliveryType === 'customerAddress')
      formData.customer.postAddress = formData.customer.legalAddress;

    let data = removeEmptyFields(formData);
    data = trimFields(data);
    if (companyInfo?.isCaptchaEnabled === true) {
      data['captchaSmartToken'] = captchaToken;
    }

    createContract(data)
      .unwrap()
      .then(() => {
        window.ym(companyInfo?.htmlCounterYaMetrika, 'reachGoal', '1zayavka', {
          order_price: cost?.fullPrice,
          currency: 'RUB',
        });
        window.ym(companyInfo?.htmlCounterYaMetrika, 'hit', `/success/${customer[customerType]}`);
        navigate(`/success/${customer[customerType]}`);
        localStorage.removeItem('regForm');
        methods.reset();
      })
      .catch((err) => {
        const {setError, setFocus} = methods;
        handleReset();
        setCaptchaTokenToken(null);
        for (const key in err.data.errors) {
          if (err.data.errors.hasOwnProperty(key)) {
            setError(key, {message: err.data.errors[key][0]});
            setFocus(key);
          }
        }
      });
  };

  const getContractCost = (routeId) => {
    const values = methods.getValues(['deliveryTypeId', 'deliveryRouteId', 'students', 'promocode']);

    const [deliveryTypeId, deliveryRouteId, students, promocode] = values;

    const programs = students.reduce((acc, student) => {
      const programs = student.programs.map((program) => program?.trainingProgramId?.id);
      return [...acc, ...programs];
    }, []);
    const elementCount = (arr, element) => arr.filter((currentElement) => currentElement === element).length;
    const removeDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) === index);

    const trainingPrograms = removeDuplicates(programs).map((program) => {
      return {trainingProgramId: program, studentCount: elementCount(programs, program)};
    });

    const pricingParameters = {trainingPrograms, deliveryTypeId};

    if (DeliveryType.expressDelivery === deliveryTypeId) {
      pricingParameters.deliveryRouteId = deliveryRouteId ? deliveryRouteId : routeId;
    }

    if (promocode) {
      pricingParameters.promocode = promocode;
    }

    calcContractCost(pricingParameters);
  };

  const errorValidate = (err) => {
    const allErrors = getErrorsList(err);
    toast(
      <Stack spacing={1} style={{overflowY: 'scroll', maxHeight: '400px'}}>
        Внимание, заполнены не все поля!
        {allErrors.map((error) => (
          <Typography>{error}</Typography>
        ))}
      </Stack>,
      {
        position: 'bottom-left',
        autoClose: false,
        closeOnClick: true,
        type: 'error',
        theme: 'colored',
      }
    );

    if (err?.customer?.budgetTypeId) {
      const element = document.getElementById('customerType');
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  const isCaptchaEnabled = companyInfo?.isCaptchaEnabled === true;

  return (
    <div data-testid="create-contract">
      <PageTitle route={'/'}>Оформление заявки на дистанционное обучение</PageTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, errorValidate)} data-testid="create-contract-form">
          <SignUpPersonalArea getContractCost={getContractCost} />
          <CreateStudent getContractCost={getContractCost} />
          <ContractInfo customerType={customerType} setCustomerType={setCustomerType} />
          <DeliveryInfo customerType={customerType} getContractCost={getContractCost} />
          {isError && <Typography color="error">Ошибка создания заявки</Typography>}
          <Stack my={2}>
            <Box sx={{alignSelf: 'center'}}>
              {isCaptchaEnabled && (
                <SmartCaptcha
                  key={resetCaptcha}
                  sitekey={companyInfo?.captchaClientKey}
                  onSuccess={setCaptchaTokenToken}
                />
              )}
            </Box>
          </Stack>
          <LoadingButton
            type="submit"
            disabled={isCaptchaEnabled && captchaToken === null}
            variant="contained"
            size="large"
            loading={isLoading}
            fullWidth
            loadingIndicator="Загрузка...">
            Сформировать заявку
          </LoadingButton>
        </form>
        <Typography variant="subtitle1" sx={{mt: {md: 3, sm: 2, s: 1}, mb: {md: 3, sm: 2, s: 1}}}>
          Нажимая кнопку «Отправить», Вы подтверждаете, что все указанные данные принадлежат лично Вам и (или) Вы имеете
          необходимое согласие на передачу таких данных, признаете, что внимательно и в полном объеме ознакомились с{' '}
          <Link href={`${companyInfo?.privacyPolicyUrl}`} target="_blank" rel="noreferrer">
            Политикой конфиденциальности персональных данных
          </Link>
          , текст и условия которых Вам понятны и Вы их принимаете в полном объеме, а также даете свое согласие на
          обработку персональных данных без оговорок и ограничений, получение электронных писем информационного
          характера (документы по обучению и прочее).
        </Typography>
        <HelpCreatContract id={formId} phone={companyInfo?.phone} />
      </FormProvider>
    </div>
  );
};

export default CreateContract;

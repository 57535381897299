import {array, object, string} from 'yup';

export const schema = object().shape({
  email: string().email('Пожалуйста введите корректный email').required('Значение не может быть пустым').default(''),
  companyName: string().required('Значение не может быть пустым').default(''),
  trainingProgramTypes: array()
    .of(string().default(''))
    .test({
      name: 'trainingProgramTypes',
      test: (value) => value.filter((elem) => elem).length,
      message: 'Не выбраны типы обучения',
    })
    .transform((value) => value.filter((id) => id))
    .default([]),
});

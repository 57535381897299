import {object, string} from 'yup';

export const schema = {
  moderate: object().shape({
    login: string().required('Значение не может быть пустым').default(''),
    password: string().required('Значение не может быть пустым').default(''),
  }),
  education: object().shape({
    login: string()
      .required('Значение не может быть пустым')
      // .typeError('Данное поле не может содержать email')
      // .transform((curr) => (curr.includes('@') ? false : curr))
      .default(''),
    password: string().required('Значение не может быть пустым').default(''),
  }),
};

import {createTheme} from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {main: '#0249fe'},
    secondary: {main: '#D0DDFF', dark: '#89AAFF'},
    success: {main: '#00ac57', contrastText: '#fff'},
  },
  breakpoints: {
    values: {
      s: 0,
      xs: 480,
      sm: 768,
      md: 1000,
      lg: 1280,
      xl: 1400,
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      fontSize: '20px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width:480px)': {
        fontSize: '25px',
      },
      '@media (min-width:768px)': {
        fontSize: '30px',
      },
      '@media (min-width:1000px)': {
        fontSize: '35px',
      },
      '@media (min-width:1280px)': {
        fontSize: '40px',
      },
    },
    h2: {
      fontSize: '18px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width:768px)': {
        fontSize: '20px',
      },
      '@media (min-width:1000px)': {
        fontSize: '22px',
      },
      '@media (min-width:1280px)': {
        fontSize: '25px',
      },
    },
    h3: {
      color: '#0249FE',
      fontSize: '18px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width:480px)': {
        fontSize: '20px',
      },
      '@media (min-width:768px)': {
        fontSize: '22px',
      },
      '@media (min-width:1000px)': {
        fontSize: '25px',
      },
      '@media (min-width:1280px)': {
        fontSize: '30px',
      },
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width:1000px)': {
        fontSize: '18px',
      },
      '@media (min-width:1280px)': {
        fontSize: '20px',
      },
    },
    h5: {
      fontSize: '16px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width:768px)': {
        fontSize: '18px',
      },
      '@media (min-width:1000px)': {
        fontSize: '20px',
      },
      '@media (min-width:1280px)': {
        fontSize: '25px',
      },
    },
    h6: {
      fontSize: '12px',
      fontWeight: 600,
      fontStyle: 'normal',
      '@media (min-width:480px)': {
        fontSize: '14px',
      },
      '@media (min-width:768px)': {
        fontSize: '16px',
      },
      '@media (min-width:1000px)': {
        fontSize: '18px',
      },
      '@media (min-width:1280px)': {
        fontSize: '20px',
      },
    },
    subtitle1: {
      color: '#404040',
      fontSize: '12px',
      fontWeight: 400,
      fontStyle: 'normal',
      '@media (min-width:1000px)': {
        fontSize: '14px',
      },
      '@media (min-width:1280px)': {
        fontSize: '16px',
      },
    },
    subtitle2: {
      fontSize: '12px',
      fontWeight: 400,
      fontStyle: 'normal',
      '@media (min-width:1000px)': {
        fontSize: '14px',
      },
      '@media (min-width:1280px)': {
        fontSize: '16px',
      },
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
      fontStyle: 'normal',
      '@media (min-width:1000px)': {
        fontSize: '16px',
      },
      '@media (min-width:1280px)': {
        fontSize: '18px',
      },
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      fontStyle: 'normal',
      '@media (min-width:768px)': {
        fontSize: '16px',
      },
      '@media (min-width:1000px)': {
        fontSize: '18px',
      },
      '@media (min-width:1280px)': {
        fontSize: '20px',
      },
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#404040',
      '@media (min-width:1000px)': {
        fontSize: '14px',
      },
      '@media (min-width:1280px)': {
        fontSize: '16px',
      },
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#0249FE',
          fontSize: '12px',
          '@media (min-width:1000px)': {
            fontSize: '14px',
          },
          '@media (min-width:1280px)': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '@media (min-width:1000px)': {
            fontSize: '16px',
          },
          '@media (min-width:1280px)': {
            fontSize: '18px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          boxShadow: 'none',
          textTransform: 'none',
          '@media (min-width:1000px)': {
            fontSize: '16px',
          },
          '@media (min-width:1280px)': {
            fontSize: '18px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '@media (min-width:1000px)': {
            fontSize: '16px',
          },
          '@media (min-width:1280px)': {
            fontSize: '18px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
  },
});

import {configureStore} from '@reduxjs/toolkit';

import {authSlice} from './slices/auth';
import {publicSlice} from './slices/public';

const store = configureStore({
  reducer: {
    [authSlice.reducerPath]: authSlice.reducer,
    [publicSlice.reducerPath]: publicSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authSlice.middleware, publicSlice.middleware),
});

export default store;

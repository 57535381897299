import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {Controller} from 'react-hook-form';
import {CustomerType} from '../../../../constants';

const RadioButtonsController = ({control, onCustomerTypeChange}) => {
  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name="customer.customerTypeId"
        render={({field: {name, value, onChange}}) => (
          <RadioGroup
            id="customerType"
            name={name}
            value={value ?? ''}
            onChange={(e) => {
              onChange(e);
              onCustomerTypeChange(e);
            }}
            sx={{flexWrap: 'nowrap', mb: 2, flexDirection: {xs: 'column', sm: 'row'}}}
            className="radio-group">
            <FormControlLabel
              value={CustomerType.individual}
              control={<Radio />}
              label="Физическое лицо"
              className="radio-label"
            />
            <FormControlLabel
              value={CustomerType.entity}
              control={<Radio />}
              label="Юридическое лицо"
              className="radio-label"
            />
            <FormControlLabel
              value={CustomerType.individualEntrepreneur}
              control={<Radio />}
              label="Индивидуальный предприниматель"
              className="radio-label"
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default RadioButtonsController;

import {Controller, get, useFormContext} from 'react-hook-form';
import {Stack, TextField, Typography} from '@mui/material';

const NestedTextField = ({label, name, handleBlur, inputProps, sx, ...props}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({field: {onChange, onBlur, name, value, ref}}) => (
        <Stack sx={sx}>
          <TextField
            name={name}
            value={value ?? ''}
            label={label}
            onChange={onChange}
            onBlur={(e) => {
              onBlur(e);
              if (handleBlur) {
                handleBlur(e);
              }
            }}
            error={!!error}
            InputProps={inputProps}
            {...props}
            fullWidth
            inputRef={ref}
          />
          {error?.message && (
            <Typography variant="caption" color="error" sx={{mt: 1, pl: 1}}>
              {error?.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

export default NestedTextField;

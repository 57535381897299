import {errors} from '../constants/errors';

export const getErrorsList = (err) => {
  const keys = Object.keys(err);

  const allErrors = [];

  keys.forEach((key) => {
    if (key === 'students') {
      allErrors.push(key);
      err[key].forEach((student, index) => {
        const studentKeys = Object.keys(student);
        const programs = studentKeys.splice(
          studentKeys.findIndex((elem) => elem === 'programs'),
          1
        );
        allErrors.push(`Обучаемый №${index + 1}`);
        allErrors.push(...studentKeys);
        student[programs].forEach((program, programIndex) => {
          allErrors.push(`Программа обучения №${programIndex + 1}`);
          allErrors.push(...Object.keys(program));
        });
      });
    } else if (key === 'postAddress') {
      allErrors.push(key);
    } else {
      allErrors.push(key);
      allErrors.push(...Object.keys(err[key]));
    }
  });

  return allErrors.map((error) => (errors[error] ? errors[error] : error));
};

import {Controller, get, useFormContext} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, Typography} from '@mui/material';
import {useSuggestPromptMutation} from '../../../app/slices/auth';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const NestedAutocomplete = ({
  name,
  label,
  type,
  validateValue = () => {},
  autoFill = () => {},
  checkDelivery = () => {},
  inputProps,
  pattern,
  ...props
}) => {
  const {
    control,
    formState: {errors},
    setError,
  } = useFormContext();

  const error = get(errors, name);

  const [suggestPrompt, {data = [], isLoading}] = useSuggestPromptMutation();

  const loadOptions = useDebouncedCallback((e, value, reason) => {
    return reason === 'input' ? suggestPrompt({type, query: value}) : null;
  }, 500);

  const optionLabel = (option) => {
    const address = option.data.address ? option.data.address.value : '';
    switch (type) {
      case 'party':
        return `${option?.value} ${address}`;
      case 'address':
        return option.unrestricted_value;
      case 'bank':
        return `${option.value} ${option.data.bic}`;
      default:
        return option.value;
    }
  };

  const options = type === 'address' ? data.map((option) => ({...option, value: option.unrestricted_value})) : data;

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({field: {name, value, onChange, onBlur, ref}}) => (
        <Autocomplete
          disablePortal
          autoComplete
          freeSolo
          name={name}
          onChange={autoFill}
          inputValue={value ?? ''}
          onInputChange={(e, value, reason) => {
            if (value !== '' && pattern) {
              if (!pattern.test(value)) {
                setError(name, {message: 'Разрешены только буквы русского алфавита и знак "-"'});
                return;
              }
            }
            onChange(value);
            loadOptions(e, value, reason);
            checkDelivery(e, value, reason);
          }}
          onBlur={(e) => {
            onBlur(e);
            validateValue(e);
          }}
          options={options}
          getOptionLabel={(option) => option.value}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props}>
                {optionLabel(option)}
              </Box>
            );
          }}
          filterOptions={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          noOptionsText="Ничего не найдено"
          loadingText="Загрузка..."
          loading={isLoading}
          {...props}
          renderInput={(params) => (
            <Box>
              <TextField {...params} error={!!error} label={label} inputRef={ref} />
              {error?.message && (
                <Typography variant="caption" color="error" sx={{mt: 1, ml: 1}}>
                  {error?.message}
                </Typography>
              )}
            </Box>
          )}
        />
      )}
    />
  );
};

export default NestedAutocomplete;

import {Controller, useFormContext} from 'react-hook-form';
import {TextField} from '@mui/material';

const NestedTextArea = ({name, label, maxLength, ...props}) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({field: {onChange, onBlur, name, value, ref}}) => (
        <TextField
          multiline
          fullWidth
          inputProps={{maxLength}}
          name={name}
          value={value ?? ''}
          label={label}
          onChange={onChange}
          minRows={3}
          onBlur={onBlur}
          helperText={value ? `${value.length}/${maxLength} символов` : null}
          inputRef={ref}
          {...props}
        />
      )}
    />
  );
};

export default NestedTextArea;

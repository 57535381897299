export const errors = {
  user: 'Шаг 1',
  surname: 'Фамилия',
  firstname: 'Имя',
  secondname: 'Отчество',
  login: 'Email',
  phone: 'Номер телефона',
  phoneExt: 'Доб.',
  students: 'Шаг 2',
  jobPlace: 'Место работы',
  profession: 'Должность',
  trainingProgramTypeId: 'Тип программы обучения',
  trainingProgramId: 'Программа обучения',
  customer: 'Шаг 3',
  name: 'Имя',
  secondName: 'Отчество',
  email: 'Email',
  passportSerial: 'Серия',
  passportNumber: 'Номер',
  passportIssuedDate: 'Дата выдачи',
  passportIssued: 'Кем выдан',
  organizationName: 'Название организации',
  budgetTypeId: 'Вид бюджета',
  personalAccount: 'Лицевой счет',
  departmentTitle: 'Наименование территориального органа Федерального казначейства',
  treasuryAccount: 'Казначейский счет',
  mainAccount: 'Единый казначейский счет (ЕКС)',
  legalAddress: 'Юридический адрес организации',
  inn: 'ИНН',
  kpp: 'КПП',
  bank: 'Банк',
  bik: 'БИК банка',
  rAccount: 'Расч. счет',
  kAccount: 'Корр. счет',
  directorProfession: 'Должность',
  basisTypeId: 'Действующий на основании',
  basisNumber: 'Номер',
  basisDate: 'Дата',
  directorSurname: 'Фамилия руководителя',
  directorName: 'Имя руководителя',
  directorSecondName: 'Отчество руководителя',
  ogrn: 'ОГРН',
  postAddress: 'Адрес доставки',
};

import {createApi} from '@reduxjs/toolkit/query/react';
import {API_URL} from '../../constants';
import {axiosBaseQuery} from '../axiosBaseQuery';

export const publicSlice = createApi({
  baseQuery: axiosBaseQuery({baseUrl: API_URL}),
  reducerPath: 'trainings',
  endpoints: (build) => ({
    getCompanySettings: build.query({
      query: () => ({
        url: 'public/company-settings',
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
    getProgramTypes: build.query({
      query: () => ({
        url: 'public/trainingProgramTypes',
        method: 'GET',
      }),
    }),
    getProgramsByType: build.query({
      query: (id) => ({
        url: `public/trainingPrograms/${id}`,
        method: 'GET',
      }),
    }),
    checkExpressDelivery: build.mutation({
      query: (data) => ({
        url: 'public/checkExpressDelivery',
        method: 'POST',
        data,
      }),
    }),
    calcContractCost: build.mutation({
      query: (data) => ({
        url: 'public/calc-contract-cost',
        method: 'POST',
        data,
      }),
    }),
    getCommercialPrograms: build.query({
      query: () => ({
        url: 'public/trainingProgramTypesForCommercialOffer',
        method: 'GET',
      }),
    }),
    sendCommercialProposal: build.mutation({
      query: (data) => ({
        url: 'public/commercialOffer',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetCompanySettingsQuery,
  useGetProgramTypesQuery,
  useGetProgramsByTypeQuery,
  useCheckExpressDeliveryMutation,
  useCalcContractCostMutation,
  useGetCommercialProgramsQuery,
  useSendCommercialProposalMutation,
} = publicSlice;

export const {getCompanySettings, getProgramTypes} = publicSlice.endpoints;

import {Link, Stack, Typography, useMediaQuery} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const PhoneNumber = ({phoneNumber}) => {
  const isDesktop = useMediaQuery('(min-width:768px)');

  return (
    <Stack className="header__info" direction="column" alignItems="flex-end">
      <Link href={`tel:${phoneNumber}`} color="#fff" className="header__info-phone">
        {isDesktop ? phoneNumber : <PhoneIcon fontSize="large" />}
      </Link>
      {isDesktop ? (
        <Typography variant="body2" fontSize={16} color="#fff" textAlign={{sx: 'center', md: 'right'}}>
          Звонок бесплатный
        </Typography>
      ) : null}
    </Stack>
  );
};

export default PhoneNumber;

import {Controller, useFormContext} from 'react-hook-form';
import {FormControlLabel, Checkbox} from '@mui/material';

const NestedCheckbox = ({customChange = () => {}, ...props}) => {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      shouldUnregister
      defaultValue={props.defaultValue}
      render={({field: {onChange, onBlur, name, value, ref}}) => (
        <FormControlLabel
          control={
            <Checkbox
              value={value}
              onChange={(e) => {
                onChange(e);
                customChange(e);
              }}
              onBlur={onBlur}
              name={name}
              ref={ref}
              {...props}
            />
          }
          label={props.label}
        />
      )}
    />
  );
};

export default NestedCheckbox;

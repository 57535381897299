import {Link, useParams} from 'react-router-dom';
import {Controller, get, useForm} from 'react-hook-form';
import {skipToken} from '@reduxjs/toolkit/query';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Container, Link as TypographyLink, Stack, Typography} from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import {useRecoverPasswordMutation, useVerifyEmailQuery} from '../../app/slices/auth';
import Loader from '../../components/Loader';

import {schema} from './schema';
import PasswordTextField from '../../components/PasswordTextField';

const ResetPassword = () => {
  const {token} = useParams();
  const {isError, isSuccess} = useVerifyEmailQuery(token ? token : skipToken);
  const [recoverPassword, {isSuccess: isPasswordSucceed}] = useRecoverPasswordMutation();

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(schema),
  });

  const handler = (data) => {
    return data.password === data.password_confirmation
      ? recoverPassword({token, data})
      : setError('password_confirmation', {message: 'Оба пароля должны быть одинаковы'});
  };

  const passwordError = get(errors, 'password');
  const passwordConfirmationError = get(errors, 'password_confirmation');

  if (isError) {
    return (
      <Container maxWidth="sm">
        <Stack spacing={3} sx={{mt: {xs: 4, sm: 8, md: 10}}} alignItems="center">
          <ErrorIcon sx={{fontSize: {xs: 60, sm: 90, md: 120}}} color="error" />
          <Typography variant="h3 " textAlign="center" gutterBottom sx={{fontSize: {xs: 20, sm: 28, md: 34}}}>
            Приносим извинения,время действия токена истекло!
          </Typography>
          <Typography variant="body1" textAlign="center" gutterBottom>
            Пожалуйста отправьте запрос на восстановление пароля повторно.
          </Typography>
          <Button component={Link} to="/forgot-password" variant="contained" size="large">
            Отправить повторно
          </Button>
        </Stack>
      </Container>
    );
  }

  return isSuccess ? (
    isPasswordSucceed ? (
      <Container maxWidth="sm">
        <Stack spacing={3} sx={{mt: {xs: 4, sm: 8, md: 10}}} alignItems="center">
          <DoneAllIcon sx={{fontSize: {xs: 60, sm: 90, md: 120}}} color="success" />
          <Typography variant="h3" textAlign="center" gutterBottom sx={{fontSize: {xs: 20, sm: 28, md: 34}}}>
            Пароль успешно изменен!
          </Typography>
          <Typography variant="body1" textAlign="center" gutterBottom>
            Ваш пароль был успешно изменен. Используйте новый пароль чтобы войти в систему.
          </Typography>
          <Button component={Link} to="/" variant="contained" size="large">
            Вернуться на главную
          </Button>
        </Stack>
      </Container>
    ) : (
      <div data-testid="reset-email">
        <Container maxWidth="sm" sx={{mt: {xs: 4, sm: 8, md: 10}}}>
          <Typography variant="h3" sx={{fontSize: {xs: 20, sm: 28, md: 34}, mb: 2}} textAlign="center" gutterBottom>
            Пожалуйста, придумайте новый пароль для своего аккаунта
          </Typography>
          <Box component="form" onSubmit={handleSubmit(handler)}>
            <Stack spacing={3}>
              <Controller
                control={control}
                name="password"
                render={({field: {onChange, onBlur, name, value, ref}}) => (
                  <PasswordTextField
                    name={name}
                    label="Придумайте новый пароль"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!passwordError}
                    helperText={passwordError?.message}
                    placeholder="vfd5!>'32-Ovc"
                    autoComplete="off"
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                control={control}
                name="password_confirmation"
                render={({field: {onChange, onBlur, name, value, ref}}) => (
                  <PasswordTextField
                    name={name}
                    label="Подтвердите новый пароль"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!passwordConfirmationError}
                    helperText={passwordConfirmationError?.message}
                    placeholder="vfd5!>'32-Ovc"
                    autoComplete="off"
                    inputRef={ref}
                  />
                )}
              />
              <Button variant="contained" size="large" type="submit">
                Отправить
              </Button>
            </Stack>
          </Box>
        </Container>
      </div>
    )
  ) : (
    <Loader />
  );
};

export default ResetPassword;

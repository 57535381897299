import {Controller, get, useFormContext} from 'react-hook-form';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';

const NestedSelect = ({name, label, options, controlStyles, ...props}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  return (
    <FormControl error={!!error} style={controlStyles}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        shouldUnregister
        render={({field: {onChange, onBlur, name, value}}) => (
          <Select
            labelId="select-label"
            name={name}
            value={value ?? ''}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            {...props}>
            {options.map((basis) => (
              <MenuItem key={basis.id} value={basis.id}>
                {basis.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

export default NestedSelect;

import {Link, Stack, Typography} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const PageTitle = ({route, sx, children}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={{sm: 3.5, s: 1}}
      mt={{md: 4.5, sm: 4, s: 3.5}}
      mb={{lg: 5, md: 4.5, xs: 3.5, s: 2.5}}
      sx={sx}>
      <Link component={RouterLink} to={route} color="#1a1a1a" lineHeight={0}>
        <ArrowBackIosNewIcon sx={{fontSize: {lg: 25, md: 20, s: 15}}} />
      </Link>
      <Typography variant="h1" textAlign="center">
        {children}
      </Typography>
    </Stack>
  );
};

export default PageTitle;

import {createApi} from '@reduxjs/toolkit/query/react';
import {API_URL} from '../../constants';
import {axiosBaseQuery} from '../axiosBaseQuery';

export const authSlice = createApi({
  baseQuery: axiosBaseQuery({baseUrl: API_URL}),
  reducerPath: 'auth',
  tagTypes: ['RegForm'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: 'auth/login',
        method: 'POST',
        data,
      }),
    }),
    createContract: builder.mutation({
      query: (data) => ({
        url: 'auth/create-contract',
        method: 'POST',
        data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (login) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        data: {login},
      }),
    }),
    verifyEmail: builder.query({
      query: (token) => ({
        url: `auth/verify-email/${token}`,
        method: 'GET',
      }),
    }),
    recoverPassword: builder.mutation({
      query: ({token, data}) => ({
        url: `auth/reset-password/${token}`,
        method: 'POST',
        data,
      }),
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: 'auth/register',
        method: 'POST',
        data,
      }),
    }),
    confirmEmail: builder.query({
      query: ({key}) => ({
        url: `auth/verify/${key}`,
        method: 'GET',
      }),
    }),
    confirmNewEmail: builder.query({
      query: ({token}) => ({
        url: `auth/verify-new-email/${token}`,
        method: 'GET',
      }),
    }),
    isEmailExist: builder.query({
      query: ({login}) => ({
        url: `auth/is-login-exists/${login}`,
        method: 'GET',
      }),
    }),
    updateDeadSoul: builder.query({
      query: ({deadSoulHash, deadSoulStatusId}) => ({
        url: `public/dead-soul/${deadSoulHash}/${deadSoulStatusId}`,
        method: 'GET',
      }),
    }),
    suggestPrompt: builder.mutation({
      query: ({type, query}) => ({
        url: 'public/dadata/suggest',
        method: 'POST',
        data: {
          type,
          query,
        },
      }),
    }),
    sendFirstForm: builder.mutation({
      query: (data) => ({
        url: 'auth/regForm',
        method: 'POST',
        data: {data},
      }),
      invalidateTags: ['RegForm'],
    }),
    getForm: builder.query({
      query: (id) => ({
        url: `auth/regForm/${id}`,
        method: 'GET',
      }),
      providesTags: ['RegForm'],
    }),
    updateForm: builder.mutation({
      query: ({id, data}) => ({
        url: `auth/regForm/${id}`,
        method: 'PUT',
        data: {data},
      }),
    }),
    sendForm: builder.mutation({
      query: ({id, email}) => ({
        url: `auth/regForm/sendMail/${id}`,
        method: 'POST',
        data: {email},
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCreateContractMutation,
  useResetPasswordMutation,
  useVerifyEmailQuery,
  useRecoverPasswordMutation,
  useSignUpMutation,
  useConfirmEmailQuery,
  useConfirmNewEmailQuery,
  useIsEmailExistQuery,
  useSuggestPromptMutation,
  useUpdateDeadSoulQuery,
  useSendFirstFormMutation,
  useUpdateFormMutation,
  useSendFormMutation,
  useGetFormQuery,
  useLazyGetFormQuery,
} = authSlice;

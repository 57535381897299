import {Stack} from '@mui/material';

import InformationBlock from './informationBlock';

import NestedCheckbox from '../../../../components/InputsRegister/ControlFields/Checkbox';
import NestedAutocomplete from '../../../../components/InputsRegister/ControlFields/Autocomplete';
import NestedMaskedTextField from '../../../../components/InputsRegister/ControlFields/MaskedTextField';
import NestedTextField from '../../../../components/InputsRegister/ControlFields/TextField';
import BasicDatePicker from '../../../../components/InputsRegister/ControlFields/DatePicker';

const IndividualForm = ({isForeignCitizenship, autoFill, setDeliveryAddress}) => {
  return (
    <InformationBlock title="Введите данные о заказчике">
      <Stack
        spacing={{s: 1}}
        direction={{sm: 'row'}}
        flexWrap={{sm: 'wrap'}}
        justifyContent="space-between"
        useFlexGap={true}>
        <Stack sx={{width: {sm: '49%', s: '100%'}}} spacing={{s: 1}}>
          <NestedAutocomplete
            name="customer.surname"
            label="Фамилия*"
            type="fio"
            validateValue={autoFill}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <NestedAutocomplete name="customer.name" label="Имя*" type="fio" pattern={/^(^[-а-яА-ЯёЁ]+$[^0-9]*)$/} />
          <NestedAutocomplete
            name="customer.secondName"
            label="Отчество"
            type="fio"
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
        </Stack>
        <Stack sx={{width: {sm: '49%', s: '100%'}}} spacing={{s: 1}}>
          <NestedAutocomplete name="customer.email" label="Email*" type="email" />
          <NestedMaskedTextField name="customer.phone" label="Телефон*" format="+#-(###)-###-####" mask="_" />
          <NestedAutocomplete
            name="customer.legalAddress"
            label="Адрес*"
            type="address"
            fullWidth
            checkDelivery={setDeliveryAddress}
          />
        </Stack>
      </Stack>
      <InformationBlock
        title="Паспорт"
        extra={
          <NestedCheckbox
            name="customer.isForeignCitizenship"
            label="Паспорт иностранного гражданина"
            defaultValue={false}
          />
        }>
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          {isForeignCitizenship ? (
            <NestedTextField name="customer.passportSerial" label="Серия*" sx={{width: {sm: '32%'}}} />
          ) : (
            <NestedMaskedTextField
              name="customer.passportSerial"
              label="Серия*"
              format="####"
              sx={{width: {sm: '32%'}}}
            />
          )}
          {isForeignCitizenship ? (
            <NestedTextField name="customer.passportNumber" label="Номер*" sx={{width: {sm: '32%'}}} />
          ) : (
            <NestedMaskedTextField
              name="customer.passportNumber"
              label="Номер*"
              format="######"
              sx={{width: {sm: '32%'}}}
            />
          )}
          <BasicDatePicker
            label="Дата выдачи*"
            name="customer.passportIssuedDate"
            disableFuture
            fullWidth
            sx={{width: {sm: '32%'}}}
          />
          <NestedAutocomplete
            name="customer.passportIssued"
            label="Кем выдан*"
            type="fms_unit"
            sx={{width: {sm: '100%'}}}
          />
        </Stack>
      </InformationBlock>
    </InformationBlock>
  );
};

export default IndividualForm;

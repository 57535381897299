import {Controller, get, useFormContext} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, Typography} from '@mui/material';
import {useSuggestPromptMutation} from '../../../app/slices/auth';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

const NestedAutocompleteBIK = ({
  name,
  label,
  type,
  validateValue = () => {},
  autoFill = () => {},
  checkDelivery = () => {},
  inputProps,
  pattern,
  ...props
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = get(errors, name);

  const [suggestPrompt, {data = [], isLoading}] = useSuggestPromptMutation();

  const loadOptions = useDebouncedCallback((e, value, reason) => {
    return reason === 'input' ? suggestPrompt({type, query: value}) : null;
  }, 500);

  const optionLabel = (option) => {
    return option?.data?.bic;
  };

  const options = data;

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({field: {name, value, onChange, onBlur, ref}}) => (
        <Autocomplete
          disablePortal
          autoComplete
          freeSolo
          name={name}
          onChange={autoFill}
          inputValue={value ?? ''}
          onInputChange={(e, value, reason) => {
            onChange(value);
            loadOptions(e, value, reason);
            checkDelivery(e, value, reason);
          }}
          onBlur={(e) => {
            onBlur(e);
            validateValue(e);
          }}
          options={options}
          getOptionLabel={(option) => option?.data?.bic}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props}>
                {optionLabel(option)}
              </Box>
            );
          }}
          filterOptions={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          noOptionsText="Ничего не найдено"
          loadingText="Загрузка..."
          loading={isLoading}
          {...props}
          renderInput={(params) => (
            <Box>
              <TextField {...params} {...inputProps} error={!!error} label={label} inputRef={ref} />
              {error?.message && (
                <Typography variant="caption" color="error" sx={{mt: 1, ml: 1}}>
                  {error?.message}
                </Typography>
              )}
            </Box>
          )}
        />
      )}
    />
  );
};

export default NestedAutocompleteBIK;

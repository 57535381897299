import {object, string} from 'yup';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import {Controller, get, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Link as TypographyLink, Stack, TextField, Typography} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import {LoadingButton} from '@mui/lab';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {useResetPasswordMutation} from '../../app/slices/auth';

export const schema = object().shape({
  login: string().email('Пожалуйста введите корректный email').required('Значение не может быть пустым').trim(),
});

const ForgotPassword = () => {
  const [resetPassword, {isSuccess, isLoading}] = useResetPasswordMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({login}) => {
    try {
      await resetPassword(login)
        .unwrap()
        .then((res) => reset());
    } catch (error) {
      switch (error?.status) {
        case 422:
          return toast.error('Пользователь с таким email не зарегистрирован');
        case 409:
          return toast.error('Запрос на изменения пароля уже отправлен, проверьте вашу почту');
        case 500:
          return toast.error('Неизвестная ошибка, попробуйте позже');
        default:
          break;
      }
    }
  };

  return (
    <div data-testid="forgot-password">
      <SwitchTransition mode="out-in">
        <CSSTransition key={isSuccess ? 'success' : 'form'} timeout={300} classNames="customer">
          {isSuccess ? (
            <Stack alignItems="center" maxWidth={400} mt={{xs: 5, sm: 10, md: 12}} mr={'auto'} ml={'auto'}>
              <MailIcon sx={{fontSize: {xs: 60, sm: 90, md: 120}}} />
              <Typography variant="h1" fontSize={{xs: 24, sm: 30}} fontWeight={600} textAlign="center" mt={4}>
                Проверьте вашу почту
              </Typography>
              <Typography variant="body1" fontSize={18} mt={2} mb={2} textAlign="center">
                Мы отправили инструкцию по восстановлению пароля на ваш email
              </Typography>
              <Button component={Link} to="/" sx={{width: '50%'}} variant="contained" size="large">
                На главную
              </Button>
            </Stack>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              mt={{xs: 5, sm: 10, md: 12}}
              mr={'auto'}
              ml={'auto'}
              maxWidth="fit-content">
              <Stack spacing={2}>
                <Typography variant="h1" gutterBottom align="center" fontWeight={600} fontSize={{xs: 26, sm: 38}}>
                  Восстановление пароля
                </Typography>
                <Controller
                  control={control}
                  name="login"
                  render={({field: {onChange, onBlur, name, value, ref}}) => (
                    <TextField
                      name={name}
                      label="Введите ваш email"
                      value={value ?? ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!get(errors, name)}
                      helperText={get(errors, name)?.message}
                      inputRef={ref}
                    />
                  )}
                />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isLoading}
                  loadingIndicator="Загрузка...">
                  Восстановить пароль
                </LoadingButton>
                <TypographyLink component={Link} to="/" color="primary" align="center">
                  Назад
                </TypographyLink>
              </Stack>
            </Box>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default ForgotPassword;

import {useEffect, useState} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {CSSTransition} from 'react-transition-group';
import {FormControl, FormControlLabel, RadioGroup, Radio, Stack, Typography} from '@mui/material';
import {DeliveryType} from '../../constants';
import {useCheckExpressDeliveryMutation} from '../../app/slices/public';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

import NestedAutocomplete from '../../components/InputsRegister/ControlFields/Autocomplete';
import NestedTextArea from '../../components/InputsRegister/ControlFields/TextArea';

import RegistrationStep from './modules/RegistrationStep';
import InformationBlock from './modules/contractInfo/informationBlock';
import Promocode from './modules/deliveryInfo/Promocode';
import ContractPrice from './modules/Price';
import {Skeleton} from '@mui/lab';

const DeliveryInfo = ({customerType, getContractCost}) => {
  const [checkExpressDelivery, {data: expressInfo, isSuccess, isLoading}] = useCheckExpressDeliveryMutation();
  const {getValues, setValue, control} = useFormContext();

  const minDelivery = expressInfo?.data?.minDay;
  const maxDelivery = expressInfo?.data?.maxDay === 1 ? 2 : expressInfo?.data?.maxDay;

  const [deliveryType, setDeliveryType] = useState(DeliveryType.withoutDelivery);

  const postAddress = useWatch({name: 'postAddress', control});
  const legalAddress = useWatch({name: 'customer.legalAddress', control});
  const isExpressDelivery = useWatch({name: 'deliveryTypeId', control}) === DeliveryType.expressDelivery;
  const isSameAddress = useWatch({name: 'isSameAddress', control});
  const deliveryTypeId = useWatch({name: 'deliveryTypeId', control});
  const formDeliveryType = useWatch({name: 'deliveryType', control});

  useEffect(() => {
    setValue('deliveryTypeId', deliveryTypeId);
    getContractCost();
  }, [customerType]);

  const checkDelivery = useDebouncedCallback((value) => {
    checkExpressDelivery({address: value})
      .unwrap()
      .then(({data}) => (isExpressDelivery ? getContractCost(data.id) : getContractCost()))
      .catch(({data}) => {
        if (deliveryTypeId === DeliveryType.postOfRussia) {
          setValue('deliveryTypeId', deliveryTypeId);
        } else if (deliveryTypeId === DeliveryType.withoutDelivery) {
          setValue('deliveryTypeId', deliveryTypeId);
        } else {
          setValue('deliveryTypeId', DeliveryType.postOfRussia);
        }
      });
  }, 700);

  useEffect(() => {
    checkDelivery(postAddress);
  }, [postAddress]);

  const setLegalAddress = () => {
    const legalAddress = getValues('customer.legalAddress');

    if (legalAddress) {
      setValue('customer.postAddress', legalAddress);
      setValue('postAddress', legalAddress);
      checkExpressDelivery({address: legalAddress});
      getContractCost();
    }
  };

  const setEmptyDeliveryAddress = () => {
    setValue('customer.postAddress', '');
    setValue('postAddress', '');
    getContractCost();
  };

  const setDeliveryRouteId = (e) => {
    if (e.target.value === DeliveryType.expressDelivery) {
      setValue('deliveryRouteId', expressInfo?.data.id);
      getContractCost(expressInfo?.data.id);
    } else {
      getContractCost();
    }
  };

  // const onDeliveryTypeChange = (e) => {
  //   if (!e.target) return;
  //   if (e.target.value === DeliveryType.withoutDelivery) {
  //     setValue('deliveryTypeId', DeliveryType.withoutDelivery);
  //   }
  //   if (e.target.value === 'customerAddress') {
  //     setValue('deliveryTypeId', DeliveryType.postOfRussia);
  //     setLegalAddress();
  //   }
  //   if (e.target.value === 'anotherAddress') {
  //     setValue('deliveryTypeId', DeliveryType.postOfRussia);
  //     setEmptyDeliveryAddress();
  //   }
  //   setDeliveryType(e.target.value);
  // };

  useEffect(() => {
    if (formDeliveryType === DeliveryType.withoutDelivery) {
      setValue('deliveryTypeId', DeliveryType.withoutDelivery);
    }
    if (formDeliveryType === 'customerAddress') {
      setValue('deliveryTypeId', DeliveryType.postOfRussia);
      setLegalAddress();
    }
    if (formDeliveryType === 'anotherAddress') {
      setValue('deliveryTypeId', DeliveryType.postOfRussia);
      setEmptyDeliveryAddress();
    }
  }, [formDeliveryType]);

  const deliveryPrice = expressInfo ? expressInfo?.data.cost : 0;

  return (
    <RegistrationStep step="Шаг 4 из 4" title="Информация для доставки оригиналов документов">
      <InformationBlock title="Выберите тип доставки">
        <Stack direction={{sm: 'row', s: 'column'}} spacing={{sm: 2, s: 1}}>
          <FormControl fullWidth style={{flex: {sm: '66%', s: '100%'}}}>
            <Controller
              control={control}
              name="deliveryType"
              render={({field: {name, value, onChange}}) => (
                <RadioGroup
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    // onDeliveryTypeChange(e);
                  }}
                  sx={{
                    flexWrap: 'nowrap',
                    mb: {sm: 2, s: 1},
                    flexDirection: {xs: 'column', sm: 'row'},
                  }}
                  className="radio-group">
                  <FormControlLabel
                    value="customerAddress"
                    control={<Radio />}
                    label="Доставка по адресу заказчика"
                    className="radio-label"
                  />
                  <FormControlLabel
                    value="anotherAddress"
                    control={<Radio />}
                    label="Доставка по другому адресу"
                    className="radio-label"
                  />
                  <FormControlLabel
                    value={DeliveryType.withoutDelivery}
                    control={<Radio />}
                    label="Доставка не требуется"
                    className="radio-label"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {/* <FormControl fullWidth style={{flex: '49%'}}>
            <Controller
              control={control}
              name="deliveryTypeId"
              render={({field: {name, value, onChange}}) => (
                <RadioGroup
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setDeliveryRouteId(e);
                  }}
                  sx={{
                    flexWrap: 'nowrap',
                    mb: 2,
                    flexDirection: {xs: 'column', sm: 'row'},
                  }}
                  className="radio-group"></RadioGroup>
              )}
            />
          </FormControl> */}
        </Stack>
        {formDeliveryType === 'customerAddress' && <Typography variant="h4">Адрес: {legalAddress}</Typography>}
        {formDeliveryType === 'anotherAddress' && (
          <>
            <NestedAutocomplete
              name="postAddress"
              label="Адрес доставки документов*"
              type="address"
              fullWidth
              disabled={isSameAddress}
            />
            <Typography variant="caption">Индекс для указанного адреса присваивается автоматически</Typography>
          </>
        )}
      </InformationBlock>
      {formDeliveryType !== DeliveryType.withoutDelivery ? (
        <InformationBlock title="Выберите способ доставки">
          <FormControl fullWidth>
            <Controller
              control={control}
              name="deliveryTypeId"
              render={({field: {name, value, onChange}}) => (
                <RadioGroup
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setDeliveryRouteId(e);
                  }}
                  sx={{flexWrap: 'nowrap', mb: 2, flexDirection: {xs: 'column', sm: 'row'}}}
                  className="radio-group"
                  style={{justifyContent: 'flex-start'}}>
                  <Stack direction="column" width="100%">
                    <FormControlLabel
                      value={DeliveryType.postOfRussia}
                      control={<Radio />}
                      label="Почта России"
                      className="radio-label"
                    />
                    <Typography variant="caption">Доставка Почтой России — бесплатно</Typography>
                  </Stack>
                  <CSSTransition in={isSuccess} timeout={300} classNames="customer" unmountOnExit>
                    <Stack direction="column" width="100%" alignItems="flex-end">
                      <FormControlLabel
                        value={DeliveryType.expressDelivery}
                        control={<Radio />}
                        label="Экспресс доставка"
                        className="radio-label"
                      />
                      {isLoading ? (
                        <Skeleton width="100%" height={40} />
                      ) : (
                        <Typography variant="caption">
                          Стоимость доставки {deliveryPrice} ₽, срок доставки — {minDelivery}-{maxDelivery} рабочих дня
                        </Typography>
                      )}
                    </Stack>
                  </CSSTransition>
                </RadioGroup>
              )}
            />
          </FormControl>
        </InformationBlock>
      ) : null}

      <InformationBlock title="Есть пожелания к заказу?">
        <Stack direction={{sm: 'row', s: 'column'}} spacing={{sm: 2, s: 1}}>
          <NestedTextArea name="wishes" label="Укажите ваше пожелание" maxLength={200} sx={{width: {sm: '49%'}}} />
          <Promocode getContractCost={getContractCost} />
          {/*<Stack sx={{width: {sm: '49%'}}} spacing={{sm: 2, s: 1}}>*/}
          {/*  <NestedTextField name="promocode" label="Промокод" />*/}
          {/*  <Button variant="contained" onClick={getContractCost}>*/}
          {/*    Применить*/}
          {/*  </Button>*/}
          {/*</Stack>*/}
        </Stack>
      </InformationBlock>
      <Typography variant="subtitle2" mt={{lg: 3.5, sm: 2.5, s: 2}}>
        * — поля, обязательные для заполнения
      </Typography>
      <ContractPrice isExpressDelivery={isExpressDelivery} />
    </RegistrationStep>
  );
};

export default DeliveryInfo;

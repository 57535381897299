import {Link, useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/query';
import {Button, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import {useConfirmEmailQuery} from '../../app/slices/auth';

const ConfirmEmail = () => {
  const {key} = useParams();
  const {isError, isSuccess} = useConfirmEmailQuery(key ? {key} : skipToken);

  if (isError) {
    return (
      <Stack spacing={3} alignItems="center" sx={{mt: {xs: 4, sm: 8, md: 10}}}>
        <ErrorIcon sx={{fontSize: {xs: 60, sm: 90, md: 120}}} color="error" />
        <Typography variant="h3" textAlign="center" gutterBottom sx={{fontSize: {xs: 20, sm: 28, md: 34}}}>
          Приносим извинения,произошла ошибка!
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Возможно вы подтвердили электронную почту ранее.
        </Typography>
        <Button component={Link} to="/" variant="contained" size="large">
          Вернуться на страницу входа
        </Button>
      </Stack>
    );
  }

  return (
    isSuccess && (
      <Stack spacing={3} sx={{mt: {xs: 4, sm: 8, md: 10}}} alignItems="center">
        <MarkEmailReadOutlinedIcon sx={{fontSize: {xs: 60, sm: 90, md: 120}}} color="success" />
        <Typography variant="h4" textAlign="center" gutterBottom sx={{fontSize: {xs: 20, sm: 28, md: 34}}}>
          Вы успешно зарегистрировались в системе!
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Ваш аккаунт был успешно подтвержден, нажмите кнопку "Продолжить", чтобы войти в систему
        </Typography>
        <Button component={Link} to="/" variant="contained" size="large">
          Продолжить
        </Button>
      </Stack>
    )
  );
};

export default ConfirmEmail;

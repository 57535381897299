import {useState} from 'react';
import {Button, ClickAwayListener, Stack, Tooltip, Typography} from '@mui/material';
import NestedTextField from '../../../../components/InputsRegister/ControlFields/TextField';
import HelpIcon from '@mui/icons-material/Help';

const Promocode = ({getContractCost}) => {
  const [showPromocodeForm, setShowPromocodeForm] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <Stack sx={{width: {sm: '49%'}}} spacing={{sm: 2, s: 1}}>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Button fullWidth variant="contained" color="secondary" onClick={() => setShowPromocodeForm((state) => !state)}>
          У меня есть промокод
        </Button>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            id="promocode-info"
            open={open}
            onClick={handleTooltipOpen}
            title={
              <Typography fontSize={14}>
                Промокод для клиентов рассылается в электронных письмах в период акций. Также присваивается менеджерами
                Учебного Центра индивидуально для постоянных клиентов. Если у Вас нет промокода - оставьте это пуле
                пустым.
              </Typography>
            }
            style={{maxWidth: 350, zIndex: 1}}
            offset={15}>
            <HelpIcon color="action" sx={{cursor: 'pointer'}} />
          </Tooltip>
        </ClickAwayListener>
      </Stack>

      {showPromocodeForm ? (
        <>
          <NestedTextField name="promocode" label="Промокод" handleBlur={getContractCost} />
          <Button variant="contained" onClick={getContractCost}>
            Применить
          </Button>
        </>
      ) : null}
    </Stack>
  );
};

export default Promocode;

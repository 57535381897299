import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {CSSTransition} from 'react-transition-group';
import {Controller} from 'react-hook-form';

import NestedAutocomplete from '../../../../components/InputsRegister/ControlFields/Autocomplete';
import NestedCheckbox from '../../../../components/InputsRegister/ControlFields/Checkbox';
import NestedTextField from '../../../../components/InputsRegister/ControlFields/TextField';
import NestedMaskedTextField from '../../../../components/InputsRegister/ControlFields/MaskedTextField';
import NestedAutocompleteBIK from '../../../../components/InputsRegister/ControlFields/AutocompleteBIK';
import NestedSelect from '../../../../components/InputsRegister/ControlFields/Select';
import BasicDatePicker from '../../../../components/InputsRegister/ControlFields/DatePicker';

import {BasisType, BudgetType} from '../../../../constants';

import InformationBlock from './informationBlock';
import HelpIcon from '@mui/icons-material/Help';

const EntityForm = ({
  autoFillCompanyFields,
  setDeliveryAddress,
  autoFillBankFields,
  autoFillBikFields,
  isBudget,
  basisType,
  control,
  errors,
}) => {
  return (
    <Box>
      <InformationBlock
        title="Введите данные организации"
        extra={<NestedCheckbox name="customer.isBudget" label="Бюджетная организация" defaultValue={false} />}>
        <CSSTransition in={isBudget} timeout={200} classNames="alert" unmountOnExit>
          <InformationBlock title="Выберите вид бюджета">
            <FormControl fullWidth>
              <Controller
                control={control}
                name="customer.budgetTypeId"
                shouldUnregister
                render={({field: {name, value, onChange}}) => (
                  <RadioGroup
                    name={name}
                    value={value ?? ''}
                    onChange={onChange}
                    sx={{flexWrap: 'nowrap', mb: 2, flexDirection: {xs: 'column', sm: 'row'}}}
                    className="radio-group">
                    <FormControlLabel
                      value={BudgetType.federal}
                      control={<Radio />}
                      label="Федеральный"
                      className="radio-label"
                    />
                    <FormControlLabel
                      value={BudgetType.regional}
                      control={<Radio />}
                      label="Региональный"
                      className="radio-label"
                    />
                    <FormControlLabel
                      value={BudgetType.local}
                      control={<Radio />}
                      label="Местный"
                      className="radio-label"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            {errors?.customer?.budgetTypeId && (
              <Typography variant="subtitle1" color="error" align="center" mb={2}>
                {errors.customer.budgetTypeId.message}
              </Typography>
            )}
            <Stack
              mb={1}
              spacing={{s: 1}}
              direction={{sm: 'row'}}
              flexWrap={{sm: 'wrap'}}
              justifyContent="space-between"
              useFlexGap={true}>
              <NestedTextField name="customer.personalAccount" label="Лицевой счет" sx={{width: {sm: '49%'}}} />
              <NestedTextField
                name="customer.departmentTitle"
                label="Наименование территориального органа Федерального казначейства"
                sx={{width: {sm: '49%'}}}
              />
              <NestedTextField name="customer.treasuryAccount" label="Казначейский счет" sx={{width: {sm: '49%'}}} />
              <NestedTextField
                name="customer.mainAccount"
                label="Единый казначейский счет (ЕКС)"
                sx={{width: {sm: '49%'}}}
              />
            </Stack>
          </InformationBlock>
        </CSSTransition>
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <NestedAutocomplete
            name="customer.organizationName"
            label="Название организации*"
            type="party"
            fullWidth
            autoFill={autoFillCompanyFields}
          />
          <NestedAutocomplete
            name="customer.legalAddress"
            label="Юридический адрес организации*"
            type="address"
            fullWidth
            checkDelivery={setDeliveryAddress}
          />
          <NestedMaskedTextField name="customer.inn" label="ИНН*" format="##########" sx={{width: {sm: '32%'}}} />
          <NestedMaskedTextField
            name="customer.kpp"
            label="КПП*"
            format="#########"
            sx={{width: {sm: '32%'}}}
            inputProps={{
              sx: {pr: '9px'},
            }}
          />
          <NestedAutocomplete
            name="customer.bank"
            label="Банк"
            type="bank"
            autoFill={autoFillBankFields}
            sx={{width: {sm: '32%'}}}
          />
          <NestedAutocompleteBIK
            name="customer.bik"
            label="БИК банка"
            type="bank"
            autoFill={autoFillBikFields}
            sx={{width: {sm: '32%'}}}
          />
          <NestedMaskedTextField
            name="customer.rAccount"
            label="Расч. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
          />
          <NestedMaskedTextField
            name="customer.kAccount"
            label="Корр. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
          />
          <NestedMaskedTextField
            name="customer.phone"
            label="Телефон организации"
            format="+7-(###)-###-####"
            mask="_"
            sx={{width: {sm: '49%'}}}
          />
          <NestedAutocomplete name="customer.email" label="Email организации" type="email" sx={{width: {sm: '49%'}}} />
        </Stack>
      </InformationBlock>
      <InformationBlock title="Руководитель">
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <Stack sx={{width: {sm: '49%', s: '100%'}}} spacing={{s: 1}}>
            <NestedAutocomplete
              name="customer.directorSurname"
              label="Фамилия*"
              type="fio"
              pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
            />
            <NestedAutocomplete
              name="customer.directorName"
              label="Имя*"
              type="fio"
              pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
            />
            <NestedAutocomplete
              name="customer.directorSecondName"
              label="Отчество"
              type="fio"
              pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
            />
          </Stack>
          <Stack sx={{width: {sm: '49%', s: '100%'}}} spacing={{s: 1}}>
            <NestedTextField name="customer.directorProfession" label="Должность*" />

            <NestedSelect name="customer.basisTypeId" label="Действующий на основании" options={BasisType} />
            {!basisType || basisType === BasisType[0].id ? null : (
              <Stack direction={{sm: 'row', s: 'column'}} spacing={{s: 1}}>
                <NestedTextField name="customer.basisNumber" label="Номер" />
                <BasicDatePicker name="customer.basisDate" label="Дата" />
              </Stack>
            )}
          </Stack>
        </Stack>
      </InformationBlock>
    </Box>
  );
};

export default EntityForm;

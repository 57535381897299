import {Link, useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/query';
import {useConfirmNewEmailQuery} from '../../app/slices/auth';

import {Button, Container, Link as TypographyLink, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import Loader from '../../components/Loader';

const ResetEmail = () => {
  const {token} = useParams();
  const {isError, isSuccess} = useConfirmNewEmailQuery(token ? {token} : skipToken);

  if (isError) {
    return (
      <Container maxWidth="sm">
        <Stack spacing={3} mt={15} alignItems="center">
          <ErrorIcon sx={{fontSize: 80}} color="error" />
          <Typography variant="h4" textAlign="center" gutterBottom>
            Приносим извинения,время действия токена истекло!
          </Typography>
          <Typography variant="body1" textAlign="center" gutterBottom>
            Данный email уже подтвержден.
          </Typography>
          <Button variant="contained" size="large">
            <TypographyLink component={Link} to="/" color="#fff" align="center">
              Вернуться на главную
            </TypographyLink>
          </Button>
        </Stack>
      </Container>
    );
  }

  return isSuccess ? (
    <Container maxWidth="sm">
      <Stack spacing={3} mt={15} alignItems="center">
        <DoneAllIcon sx={{fontSize: 80}} color="success" />
        <Typography variant="h4" textAlign="center" gutterBottom>
          Email успешно изменен!
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Ваш email был успешно изменен. Используйте новый email для входа в систему.
        </Typography>
        <Button variant="contained" size="large">
          <TypographyLink component={Link} to="/" color="#fff" align="center">
            Вернуться на главную
          </TypographyLink>
        </Button>
      </Stack>
    </Container>
  ) : (
    <Loader />
  );
};

export default ResetEmail;

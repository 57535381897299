import {array, boolean, object, setLocale, string} from 'yup';
import extractPhone from '../../utils/extractPhone';
import {BasisType, CustomerType, defaultStudent, DeliveryType} from '../../constants';
import tipText from '../../utils/tipText';

const symbolTytles = ['символ', 'символа', 'символов'];

setLocale({
  mixed: {
    default: '',
    required: 'Это обязательное поле',
  },
  string: {
    email: 'Неверный формат',
    min: ({min}) => `минимум ${tipText(min, symbolTytles)}`,
  },
});

export const defaultSchema = object({
  user: object({
    surname: string().required().trim().default(''),
    firstname: string().required().trim().default(''),
    secondname: string().trim().default(''),
    login: string().email().required().trim().default(''),
    phone: string()
      .min(11, 'Только абоненты РФ. Введите 10 символов без 8(+7)')
      .transform((value) => extractPhone(value))
      .required()
      .default(''),
    phoneExt: string().nullable().trim().default(''),
  }),
  students: array()
    .of(
      object({
        surname: string().required().trim().default(''),
        firstname: string().required().trim().default(''),
        secondname: string().trim().default(''),
        jobPlace: string().required().min(3).trim().default(''),
        profession: string().required().min(3).trim().default(''),
        programs: array().of(
          object({
            trainingProgramTypeId: string()
              .required('Выберите тип программы обучения')
              .transform((val) => val?.id)
              .default(''),
            trainingProgramId: string()
              .required('Выберите программу обучения')
              .transform((val) => val?.id)
              .default(''),
          })
        ),
      })
    )
    .default([{...defaultStudent}]),
  promocode: string().nullable().trim().default(''),
  isSameAddress: boolean().default(false),
  deliveryTypeId: string().required().default(DeliveryType.withoutDelivery),
  deliveryType: string().nullable().default(''),
  deliveryRouteId: string().nullable().default(''),
  wishes: string().nullable().trim().default(''),
  postAddress: string().when(['deliveryTypeId', 'deliveryType'], {
    is: (deliveryTypeId, deliveryType) =>
      deliveryTypeId !== DeliveryType.withoutDelivery && deliveryType === 'anotherAddress',
    then: string().required().trim().default(''),
    otherwise: string().nullable().trim().default(''),
  }),
});

export const schema = {
  [CustomerType.individual]: object({
    ...defaultSchema.fields,
    customer: object({
      customerTypeId: string().default(CustomerType.individual),
      surname: string().required().trim().default(''),
      name: string().required().trim().default(''),
      secondName: string().trim(),
      phone: string()
        .required()
        .min(11)
        .transform((value) => extractPhone(value))
        .default(''),
      email: string().email().required().trim().default(''),
      legalAddress: string().required().trim().default(''),
      passportSerial: string().when('isForeignCitizenship', {
        is: false,
        then: string()
          .required()
          .length(4, 'серия должна быть 4 символа')
          .matches(/^-?\d+\.?\d*$/, 'Серия паспорта может содержать только числа')
          .trim()
          .default(''),
        otherwise: string().required().trim().default(''),
      }),
      passportNumber: string().when('isForeignCitizenship', {
        is: false,
        then: string()
          .required()
          .length(6, 'номер должен быть 6 символов')
          .matches(/^-?\d+\.?\d*$/, 'Серия паспорта может содержать только числа')
          .trim()
          .default(''),
        otherwise: string().required().trim().default(''),
      }),
      passportIssuedDate: string().required().typeError('Пожалуйста укажите дату').default(''),
      passportIssued: string().required().trim().default(''),
      isForeignCitizenship: boolean().default(false),
    }),
  }),
  [CustomerType.entity]: object({
    ...defaultSchema.fields,
    customer: object({
      customerTypeId: string().default(CustomerType.entity),
      organizationName: string().required().trim().default(''),
      isBudget: boolean().default(false),
      budgetTypeId: string().when('isBudget', {
        is: true,
        then: string().required().typeError('Пожалуйста укажите тип бюджета').default(''),
        otherwise: string().nullable().default(''),
      }),
      personalAccount: string().nullable().default(''),
      departmentTitle: string().nullable().default(''),
      treasuryAccount: string().nullable().default(''),
      mainAccount: string().nullable().default(''),
      legalAddress: string().required().trim().default(''),
      inn: string()
        .required()
        .min(10, 'ИНН должен быть минимум 10 символов')
        .max(12, 'ИНН не должен превышать 12 символов')
        .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
        .trim()
        .default(''),
      kpp: string()
        .required()
        .length(9, 'КПП должен быть 9 символов')
        .matches(/^-?\d+\.?\d*$/, 'КПП может содержать только числа')
        .trim()
        .default(''),
      bank: string().trim().default(''),
      bik: string()
        .transform((value) => (!!value ? value : null))
        .nullable()
        .max(9, 'БИК должен быть 9 символов')
        .matches(/^-?\d+\.?\d*$/, 'БИК может содержать только числа')
        .trim()
        .default(null),
      rAccount: string()
        // .required()
        // .length(20, 'Расч. счет должен быть 20 символов')
        // .matches(/^-?\d+\.?\d*$/, 'Расчетный счет может содержать только числа')
        .trim()
        .default(''),
      kAccount: string()
        // .required()
        // .length(20, 'Корр. счет должен быть 20 символов')
        // .matches(/^-?\d+\.?\d*$/, 'Корр. счет может содержать только числа')
        .trim()
        .default(''),
      phone: string()
        .nullable()
        .transform((value) => extractPhone(value))
        .default(''),
      email: string().nullable().email().trim().default(''),
      directorProfession: string().required().trim().default(''),
      basisTypeId: string().required().default(''),
      basisNumber: string()
        .trim()
        .when('basisTypeId', {
          is: (value) => value === BasisType[0].id,
          then: string().nullable().default(''),
          otherwise: string().required().default(''),
        }),
      basisDate: string().when('basisTypeId', {
        is: (value) => value === BasisType[0].id,
        then: string().nullable().default(''),
        otherwise: string().required().default(''),
      }),
      directorSurname: string().required().trim().default(''),
      directorName: string().required().trim().default(''),
      directorSecondName: string().trim().default(''),
    }),
  }),
  [CustomerType.individualEntrepreneur]: object({
    ...defaultSchema.fields,
    customer: object({
      customerTypeId: string().default(CustomerType.individualEntrepreneur),
      organizationName: string().required().trim().default(''),
      legalAddress: string().required().trim().default(''),
      inn: string()
        .required()
        .min(10, 'ИНН должен быть минимум 10 символов')
        .max(12, 'ИНН не должен превышать 12 символов')
        .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
        .trim()
        .default(''),
      ogrn: string()
        .default(null)
        .nullable()
        .transform((value) => (!!value ? value : null))
        .min(13, 'ОГРН не должен быть меньше 13 символов')
        .max(15, 'ОГРН не должен быть больше 15 символов')
        .matches(/^-?\d+\.?\d*$/, 'ОГРН может содержать только числа')
        .trim(),
      bank: string().nullable().trim().default(''),
      bik: string()
        .transform((value) => (!!value ? value : null))
        .nullable()
        .max(9, 'БИК должен быть 9 символов')
        .matches(/^-?\d+\.?\d*$/, 'БИК может содержать только числа')
        .trim()
        .default(null),
      rAccount: string()
        .nullable()
        .max(20, 'Расч. счет должен быть 20 символов')
        // .matches(/^-?\d+\.?\d*$/, 'Расчетный счет может содержать только числа')
        .trim()
        .default(''),
      kAccount: string()
        .nullable()
        .max(20, 'Корр. счет должен быть 20 символов')
        // .matches(/^-?\d+\.?\d*$/, 'Корр. счет может содержать только числа')
        .trim()
        .default(''),
      phone: string()
        .nullable()
        .transform((value) => extractPhone(value))
        .default(''),
      email: string().nullable().email().trim().default(''),
      directorSurname: string().required().trim().default(''),
      directorName: string().required().trim().default(''),
      directorSecondName: string().nullable().trim().default(''),
    }),
  }),
};

export default function extractPhone(phone) {
  let newPhone = '';

  phone.split('').forEach((l) => {
    if (!isNaN(parseInt(l))) {
      newPhone = newPhone + l;
    }
  });
  return newPhone;
}

import {Link} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import {InputAdornment, Stack, Typography, Tooltip as MaterialTooltip, ClickAwayListener} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {Tooltip} from 'react-tooltip';
import store from '../../app/store';
import {authSlice} from '../../app/slices/auth';

import NestedAutocomplete from '../../components/InputsRegister/ControlFields/Autocomplete';
import NestedTextField from '../../components/InputsRegister/ControlFields/TextField';
import NestedMaskedTextField from '../../components/InputsRegister/ControlFields/MaskedTextField';

import RegistrationStep from './modules/RegistrationStep';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import {useState} from 'react';

const SignUpPersonalArea = () => {
  const {
    setError,
    clearErrors,
    getValues,
    formState: {errors},
  } = useFormContext();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const checkIfEmailExist = (e) => {
    store
      .dispatch(authSlice.endpoints.isEmailExist.initiate({login: e.target.value}, {refetchOnFocus: true}))
      .unwrap()
      .then(({isLoginExists}) => {
        isLoginExists
          ? setError('user.login', {
              type: 'custom',
              message: 'Пользователь с таким email уже зарегистрирован',
            })
          : clearErrors('user.login');
      });
  };

  useDebouncedCallback(() => {
    const email = getValues().user.login;
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email && regex.test(email)) {
      store
        .dispatch(authSlice.endpoints.isEmailExist.initiate({login: email}, {refetchOnFocus: true}))
        .unwrap()
        .then(({isLoginExists}) => {
          isLoginExists
            ? setError('user.login', {
                type: 'custom',
                message: 'Пользователь с таким email уже зарегистрирован',
              })
            : clearErrors('user.login');
        });
    }
  }, 1500);

  const isLoginExists = errors?.user?.login?.type === 'custom';

  return (
    <RegistrationStep
      step="Шаг 1 из 4"
      title="Регистрация личного кабинета"
      subtitle="Введите данные администратора, который будет управлять аккаунтом"
      notification="* — поля, обязательные для заполнения">
      <Stack
        mt={{s: 2}}
        mb={{s: 2}}
        spacing={{s: 1}}
        direction={{sm: 'row'}}
        flexWrap={{sm: 'wrap'}}
        justifyContent="space-between"
        useFlexGap={true}>
        <Stack sx={{width: {sm: '49%', s: '100%'}}} spacing={{s: 1}}>
          <NestedAutocomplete
            name="user.surname"
            label="Фамилия*"
            type="fio"
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <NestedAutocomplete name="user.firstname" label="Имя*" type="fio" pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/} />
          <NestedAutocomplete
            name="user.secondname"
            label="Отчество / при наличии"
            type="fio"
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
        </Stack>
        <Stack sx={{width: {sm: '49%', s: '100%'}}} spacing={{s: 1}}>
          <NestedAutocomplete
            name="user.login"
            label="Email*"
            type="email"
            validateValue={checkIfEmailExist}
            data-tooltip-id="user-exist"
          />
          {isLoginExists ? (
            <Tooltip id="user-exist" clickable style={{maxWidth: 300, zIndex: 1}} offset={15}>
              <Typography variant="body2">
                <span>Пользователь с данным email уже зарегистрирован, чтобы восстановить пароль перейдите </span>
                <Link to="/forgot-password" style={{color: '#3877f2'}}>
                  на страницу восстановления пароля
                </Link>
                <span>, либо введите другой email чтобы продолжить регистрацию</span>
              </Typography>
            </Tooltip>
          ) : null}
          <NestedMaskedTextField name="user.phone" label="Телефон*" format="+7-(###)-###-####" mask="_" />
          <NestedTextField
            name="user.phoneExt"
            label="Доб."
            fullWidth
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <MaterialTooltip
                      id="phone-ext"
                      open={open}
                      onClick={handleTooltipOpen}
                      title={
                        <Typography fontSize={14}>
                          Добавочный номер - это короткий дополнительный номер, который часто присваивается конкретному
                          сотруднику, подразделению. При отсутствии короткого добавочного можно указать контактный
                          резервный номер телефона.
                        </Typography>
                      }
                      style={{maxWidth: 350, zIndex: 1}}
                      offset={15}>
                      <HelpIcon sx={{cursor: 'pointer'}} />
                    </MaterialTooltip>
                  </ClickAwayListener>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </RegistrationStep>
  );
};
export default SignUpPersonalArea;

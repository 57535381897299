import {useFieldArray, useWatch} from 'react-hook-form';
import {Box, Button, Typography} from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import ProgramsController from './programsController';
import ListenerCardProgramsTitle from './ListenerCardProgramsTitle';

import {defaultProgram} from '../../../constants';
import tipText from '../../../utils/tipText';

const EducationPrograms = ({
  studentIndex,
  getContractCost,
  previousStudentPrograms,
  studentQuantity,
  removeStudent,
}) => {
  const {fields, append, remove, replace} = useFieldArray({name: `students[${studentIndex}].programs`});

  const removeProgram = (index) => () => {
    remove(index);
    getContractCost();
  };

  const addProgram = (program) => () => append(program);
  const copyPrograms = (programs) => () => {
    if (!programs) return;
    const programsForCopy = programs?.filter(
      (program) => program?.trainingProgramId !== '' && program?.trainingProgramTypeId !== ''
    );
    if (programsForCopy.length === 0) return;
    replace(programsForCopy);
    getContractCost();
  };

  const programsTips = ['программу', 'программы', 'программ'];

  return (
    <Box width={{sm: '55%'}}>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <ListenerCardProgramsTitle
            educationProgram={index + 1}
            educationProgramQuantity={fields.length}
            studentQuantity={studentQuantity}
            removeProgram={removeProgram(index)}
            removeStudent={removeStudent}
          />
          <ProgramsController studentIndex={studentIndex} programIndex={index} getContractCost={getContractCost} />
        </Box>
      ))}
      {fields.length < 5 ? (
        <>
          <Button
            startIcon={<AddCircleRoundedIcon fontSize="20px" sx={{color: '#0249FE'}} />}
            onClick={addProgram(defaultProgram)}>
            <Typography variant="caption" color="#0249FE" fontWeight={500}>
              Добавить программу обучения
            </Typography>
          </Button>
          <Typography variant="caption" display="block" mb={{lg: 3.5, md: 2.5, s: 2}}>
            Можно добавить еще {tipText(5 - fields.length, programsTips)}
          </Typography>
        </>
      ) : (
        <Typography variant="caption" display="block" mb={{lg: 3.5, md: 2.5, s: 2}}>
          Добавлено максимальное количество программ
        </Typography>
      )}

      {studentIndex >= 1 && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          onClick={copyPrograms(previousStudentPrograms)}>
          Копировать программы у слушателя выше
        </Button>
      )}
    </Box>
  );
};

export default EducationPrograms;

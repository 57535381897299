import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import Root from './pages/Root';
import Login from './pages/Login';
import CreateContract from './pages/CreateContract';
import ForgotPassword from './pages/ForgotPassword';
import ConfirmEmail from './pages/ConfirmEmail';
import ResetPassword from './pages/ResetPassword';
import ResetEmail from './pages/ResetEmail';
import Success from './pages/Success';
import ErrorPage from './pages/Error';
import Loader from './components/Loader';

import {ThemeProvider} from '@mui/material';
import {theme} from './theme';
import DeadSouls from './pages/DeadSouls';
import CommercialProposal from './pages/CommercialProposal';

export const routes = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {path: '/', element: <Login />},
      {path: 'forgot-password', element: <ForgotPassword />},
      {path: 'create-contract', element: <CreateContract />},
      {path: 'verify/:key', element: <ConfirmEmail />},
      {path: 'success/:customer', element: <Success />},
      {path: 'verify-email/:token', element: <ResetPassword />},
      {path: 'verify-new-email/:token', element: <ResetEmail />},
      {path: 'dead-soul/:deadSoulHash/:deadSoulStatusId', element: <DeadSouls />},
      {path: 'commercial-proposal', element: <CommercialProposal />},
    ],
  },
];

function App() {
  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider theme={theme}>
      <div className="page">
        <RouterProvider router={router} fallbackElement={<Loader />} />
      </div>
    </ThemeProvider>
  );
}

export default App;

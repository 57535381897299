import {Box, InputAdornment, Stack, Typography} from '@mui/material';

import NestedAutocomplete from '../../../../components/InputsRegister/ControlFields/Autocomplete';
import NestedTextField from '../../../../components/InputsRegister/ControlFields/TextField';
import NestedMaskedTextField from '../../../../components/InputsRegister/ControlFields/MaskedTextField';
import InformationBlock from './informationBlock';
import HelpIcon from '@mui/icons-material/Help';
import NestedAutocompleteBIK from '../../../../components/InputsRegister/ControlFields/AutocompleteBIK';

const IndividualEntrepreneurForm = ({
  autoFillCompanyFields,
  autoFillBankFields,
  autoFillBikFields,
  setDeliveryAddress,
}) => {
  return (
    <Box>
      <InformationBlock title="Введите данные ИП">
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap>
          <NestedAutocomplete
            name="customer.organizationName"
            label="Название ИП*"
            type="party"
            fullWidth
            autoFill={autoFillCompanyFields}
          />
          <NestedAutocomplete
            name="customer.legalAddress"
            label="Адрес*"
            type="address"
            fullWidth
            checkDelivery={setDeliveryAddress}
          />
          <NestedTextField name="customer.inn" label="ИНН*" sx={{width: {sm: '32%'}}} />
          <NestedTextField name="customer.ogrn" label="ОГРН" sx={{width: {sm: '32%'}}} />
          <NestedAutocomplete
            name="customer.bank"
            label="Банк"
            type="bank"
            autoFill={autoFillBankFields}
            sx={{width: {sm: '32%'}}}
          />
          <NestedAutocompleteBIK
            name="customer.bik"
            label="БИК банка"
            type="bank"
            autoFill={autoFillBikFields}
            sx={{width: {sm: '32%'}}}
          />
          <NestedMaskedTextField
            name="customer.rAccount"
            label="Расч. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
            inputProps={{
              sx: {pr: '9px'},
            }}
          />
          <NestedMaskedTextField
            name="customer.kAccount"
            label="Корр. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
            inputProps={{
              sx: {pr: '9px'},
            }}
          />
          <NestedMaskedTextField
            name="customer.phone"
            label="Телефон ИП"
            format="+7-(###)-###-####"
            mask="_"
            sx={{width: {sm: '49%'}}}
          />
          <NestedAutocomplete name="customer.email" label="Email ИП" type="email" sx={{width: {sm: '49%'}}} />
        </Stack>
      </InformationBlock>
      <InformationBlock title="Введите данные руководителя">
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <NestedAutocomplete
            name="customer.directorSurname"
            label="Фамилия*"
            type="fio"
            sx={{width: {sm: '49%'}}}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <NestedAutocomplete
            name="customer.directorName"
            label="Имя*"
            type="fio"
            sx={{width: {sm: '49%'}}}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <NestedAutocomplete
            name="customer.directorSecondName"
            label="Отчество"
            type="fio"
            sx={{width: {sm: '49%'}}}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
        </Stack>
      </InformationBlock>
    </Box>
  );
};

export default IndividualEntrepreneurForm;

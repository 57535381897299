import {Controller, get, useFormContext} from 'react-hook-form';
import {PatternFormat} from 'react-number-format';
import {TextField} from '@mui/material';
import {useState} from 'react';

const NestedMaskedTextField = ({name, label, format = '', mask, inputProps, ...props}) => {
  const [isFormat, setIsFormat] = useState(null);
  const {
    formState: {errors},
    control,
  } = useFormContext();

  const error = get(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <PatternFormat
          customInput={TextField}
          name={name}
          value={value}
          label={label}
          format={isFormat ? format : ''}
          mask={mask}
          onChange={onChange}
          onFocus={() => setIsFormat(true)}
          onValueChange={() => (value ? setIsFormat(true) : null)}
          onBlur={(e) => {
            onBlur(e);
            setIsFormat(false);
          }}
          inputRef={ref}
          allowEmptyFormatting
          InputProps={inputProps}
          error={!!error}
          helperText={error?.message}
          {...props}
        />
      )}
    />
  );
};

export default NestedMaskedTextField;

import {Link} from 'react-router-dom';
import {Box, Button, Stack, Typography} from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const Success = () => {
  return (
    <Box sx={{border: '2px solid #00ac57', borderRadius: 4, margin: '50px auto 40px'}} maxWidth={600}>
      <Stack alignItems="center">
        <DoneAllIcon color="success" sx={{fontSize: {xs: 60, sm: 90, md: 120}}} />
        <Typography variant="h3" textAlign="center" gutterBottom sx={{fontSize: {xs: 24, sm: 30, md: 38}}}>
          Регистрация завершена
        </Typography>
        <Typography variant="body1" textAlign="center" m={2} sx={{fontSize: {xs: 14, sm: 16, md: 18}}}>
          Мы отправили ссылку для активации аккаунта на Вашу электронную почту. Пожалуйста, нажмите на ссылку в этом
          сообщении для завершения регистрации.
        </Typography>
        <Button
          variant="contained"
          color="success"
          size="large"
          component={Link}
          to="/"
          replace
          sx={{mb: 2, pl: 6, pr: 6}}>
          Продолжить
        </Button>
      </Stack>
    </Box>
  );
};

export default Success;

import {useFormContext} from 'react-hook-form';
import {Box, Button, Stack, Typography} from '@mui/material';
import classNames from 'classnames';

import {useLoginMutation} from '../../../app/slices/auth';
import {accountType, ADMIN_URL, CLIENT_URL} from '../../../constants';
import FormFields from './FormFields';

import styles from '../styles.scss';

const cx = classNames.bind(styles);

const AuthForm = ({
  isFormActive,
  setAuthGoal,
  goal,
  description,
  loginLabel,
  passwordLabel,
  loginPlaceholder,
  children,
}) => {
  const [login, {isLoading, isError, error}] = useLoginMutation();

  const {
    handleSubmit,
    reset,
    clearErrors,
    formState: {errors},
  } = useFormContext();

  const selectAuthGoal = () => {
    if (errors) {
      clearErrors();
    }
    setAuthGoal();
  };

  const authorize = (data) => {
    login(data)
      .unwrap()
      .then(({accountTypeId, accessToken}) => {
        const accountUrl = accountTypeId === accountType.admin ? ADMIN_URL : CLIENT_URL;
        const url = new URL(accountUrl);
        url.searchParams.append('accountId', accountTypeId);
        url.searchParams.append('accessToken', accessToken);

        document.location.replace(url);
      })
      .then(() => reset())
      .catch((err) => console.log(err));
  };

  return (
    <Box
      component="form"
      className={cx('auth__form', {'auth__form-active': isFormActive})}
      onSubmit={handleSubmit(authorize)}>
      <Stack flexDirection={{sm: 'row'}} justifyContent="flex-start" gap={{md: 3.5, sm: 2.5, s: 1}}>
        <Box width={{sm: '45%', s: '100%'}}>
          <Button
            variant="contained"
            color="secondary"
            className={cx({active: isFormActive})}
            disabled={isFormActive}
            fullWidth
            size="large"
            onClick={selectAuthGoal}>
            {goal}
          </Button>
          <Typography variant="body2" display="block" mt={{md: 2.5, s: 1}} textAlign="center">
            {description}
          </Typography>
        </Box>
        {isFormActive ? (
          <Box width={{sm: '50%', s: '100%'}}>
            <FormFields loginLabel={loginLabel} passwordLabel={passwordLabel} loginPlaceholder={loginPlaceholder}>
              {isError && (
                <Typography color="error" align="center" mt={{sm: 2, s: 1}}>
                  {error?.data?.userMessage}
                </Typography>
              )}
              <Stack spacing={1}>
                <Button type="submit" variant="contained" size="large" color="primary" disabled={isLoading} fullWidth>
                  Войти
                </Button>
                {children}
              </Stack>
            </FormFields>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default AuthForm;

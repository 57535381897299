import {Box, Stack, TextField} from '@mui/material';
import {Controller, get, useFormContext} from 'react-hook-form';

import PasswordTextField from '../../../components/PasswordTextField';

const FormFields = ({loginLabel, loginPlaceholder, passwordLabel, children}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const loginError = get(errors, 'login');
  const passwordError = get(errors, 'password');

  return (
    <Stack spacing={{sm: 2, s: 1}}>
      <Controller
        control={control}
        name="login"
        render={({field: {onChange, onBlur, name, value, ref}}) => (
          <TextField
            name={name}
            label={loginLabel}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!loginError}
            helperText={loginError?.message}
            placeholder={loginPlaceholder}
            autoComplete="username"
            InputLabelProps={{shrink: true}}
            inputRef={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({field: {onChange, onBlur, name, value, ref}}) => (
          <PasswordTextField
            name={name}
            label={passwordLabel}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!passwordError}
            helperText={passwordError?.message}
            placeholder="*********"
            autoComplete="current-password"
            InputLabelProps={{shrink: true}}
            inputRef={ref}
          />
        )}
      />
      {children}
    </Stack>
  );
};

export default FormFields;
